// Typography
// ––––––––––––––––––––––––––––––––––––––––––––––––––

body
    font-family: "UniNeue", sans-serif

b,
strong
    font-weight: bold

p
    margin-top: 0

h1,
h2,
h3,
h4,
h5,
h6
    letter-spacing: -0.1rem
    margin-bottom: 2rem
    margin-top: 0

h1
    font-size: 4.6rem
    line-height: 1.2

h2
    font-size: 3.6rem
    line-height: 1.25

h3
    font-size: 2.8rem
    line-height: 1.3

h4
    font-size: 2.2rem
    letter-spacing: -0.08rem
    line-height: 1.35

h5
    font-size: 1.8rem
    letter-spacing: -0.05rem
    line-height: 1.5

h6
    font-size: 1.6rem
    letter-spacing: 0
    line-height: 1.4


// Typography classes

.header-1
    font-style: normal
    font-weight: normal
    font-size: 44px
    line-height: 1
.header-2
    font-style: normal
    font-weight: normal
    font-size: 32px
    line-height: 1
.large-text
    font-weight: 500
    font-size: 20px
    line-height: 27px
.label-text
    font-weight: bold
    font-size: 14px
    line-height: 19px
    letter-spacing: 1px
    text-transform: uppercase
.input-text
    font-weight: 500
    font-size: 16px
    line-height: 22px
    opacity: 0.7
.default-text
    font-weight: 500
    font-size: 15px
    line-height: 20px
    opacity: 0.7
.tab-header
    font-weight: bold
    font-size: 14px
    line-height: 19px
.small-text
    font-weight: 500
    font-size: 12px
    line-height: 17px
    opacity: 0.5
.smallest-text
    font-weight: 500
    font-size: 10px
    line-height: 14px
    opacity: 0.7

.list-header-text
    text-transform: uppercase
    font-weight: 700
    font-size: 12px
