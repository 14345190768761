.hover-popup
  position: relative
  display: inline-block
  vertical-align: top
  // margin: 5px
  white-space: nowrap
  &:hover:before
    opacity: 1
  &:after
    font-size: 10px
    content: attr(data-icon)
  &:before
    pointer-events: none
    margin-left: 10px
    transition: 0.25s
    background: #fff
    box-shadow: 0 1px 4px -3px rgba(#000, 0.5)
    text-align: center
    transform: translateY(-50%)
    min-width: 150px
    font-size: 10px
    position: absolute
    opacity: 0
    padding: 10px
    content: attr(data-text)
    left: 100%
    top: 50%
  &.hover-popup--top
    &:before      
      margin: 0
      bottom: 100%
      left: 0%
      top: auto
      margin-bottom: 5px
  
  &.hover-popup--left
    &:before 
      left: auto
      right: 100%
      margin: 0
      margin-right: 10px
  &.hover-popup--right
    &:before 
      right: auto
      left: 100%
      margin: 0
      margin-left: 10px    
  &.hover-popup--bottom
    &:before       
      margin: 0
      top: 100%
      left: 0%
      bottom: auto
      margin-top: 5px
