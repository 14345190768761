$spacing-step: 10px
$spacing-count: 10

// Margin
@for $i from 1 through $spacing-count
  .m-#{$i}
    margin: $i * $spacing-step
  .ml-#{$i}
    margin-left: $i * $spacing-step
  .mr-#{$i}
    margin-right: $i * $spacing-step
  .mt-#{$i}
    margin-top: $i * $spacing-step
  .mb-#{$i}
    margin-bottom: $i * $spacing-step
  .my-#{$i}
    margin-bottom: $i * $spacing-step
    margin-top: $i * $spacing-step
  .mx-#{$i}
    margin-left: $i * $spacing-step
    margin-right: $i * $spacing-step

// Padding
@for $i from 1 through $spacing-count
  .p-#{$i}
    padding: $i * $spacing-step
  .pl-#{$i}
    padding-left: $i * $spacing-step
  .pr-#{$i}
    padding-right: $i * $spacing-step
  .pt-#{$i}
    padding-top: $i * $spacing-step
  .pb-#{$i}
    padding-bottom: $i * $spacing-step
  .py-#{$i}
    padding-top: $i * $spacing-step
    padding-bottom: $i * $spacing-step
  .px-#{$i}
    padding-left: $i * $spacing-step
    padding-right: $i * $spacing-step
  .pi-#{$i}
    padding: $i * $spacing-step !important
  .pli-#{$i}
    padding-left: $i * $spacing-step !important
  .pri-#{$i}
    padding-right: $i * $spacing-step !important
  .pti-#{$i}
    padding-top: $i * $spacing-step !important
  .pbi-#{$i}
    padding-bottom: $i * $spacing-step !important
  .pyi-#{$i}
    padding-top: $i * $spacing-step !important
    padding-bottom: $i * $spacing-step !important
  .pxi-#{$i}
    padding-left: $i * $spacing-step !important
    padding-right: $i * $spacing-step !important