.prices.page
    grid-template-areas: "headerMenu headerMenu" "optionsMenu pricesContent"
    grid-template-columns: 330px 1fr
    grid-template-rows: 140px 1fr
    display: grid
    height: 100%

    .header.widget
        grid-area: headerMenu

    .options
        background-color: #F1F2F5
        flex-direction: column
        grid-area: optionsMenu
        overflow: auto
        display: flex

        & > .header
            text-transform: uppercase
            letter-spacing: 0.5px
            line-height: 16px
            font-weight: 600
            font-size: 12px
            color: #6C6F88

        .builder
            .form-item
                margin: 10px 0px

        .protein-slider
            border: 1px solid #D6D8E5
            background-color: #fff
            flex-direction: column
            border-radius: 4px
            margin: 5px 0
            display: flex

            .label
                color: rgba(0, 0, 0, 0.46)
                font-weight: 500
                margin: 5px 12px
                font-size: 12px

            .value
                color: rgba(0, 0, 0, 0.8)
                font-weight: 500
                margin: 0px 12px
                font-size: 14px

            .entry
                margin: 5px 18px 15px 18px

    & > .content
        grid-area: pricesContent
        height: 100%

        &:not(.empty)
            justify-content: center
            flex-direction: column
            display: flex

        &.empty .header
            +flex(column, center, center)
            height: 100%

            .title
                color: rgba(0, 0, 0, 0.7)
                text-align: center
                line-height: 30px
                margin-top: 15px
                font-size: 22px
                width: 430px

    .table-container
        +flex(column, flex-start, stretch)
        width: 100%
        height: 100%
        max-height: 100%
        padding: 32px
        padding-bottom: 0
        box-sizing: border-box

    .table-container, .content
        .header
            +flex(column, center, flex-start)
            grid-area: pricesHeader

            .title
                color: rgba(0, 0, 0, 0.7)
                font-weight: 300
                font-size: 32px

            .subtitle
                margin-top: 5px
                font-weight: 400
                font-size: 20px
                color: #989AAC

    .content-container
        grid-template-areas: "pricesHeader" "tableHeader" "tableContent"
        grid-template-rows: 100px 50px 1fr
        display: grid
        height: 100%
        width: 98%

        .content
            display: flex
            flex-direction: column
            justify-content: flex-start
            padding-top: 32px

        .table-overlay
            flex: auto

        @media (min-width: 768px)
            width: 98%

        @media (min-width: 1367px)
            width: 95%

        .content > .header
            +flex(column, center, flex-start)
            grid-area: pricesHeader

            .title
                color: rgba(0, 0, 0, 0.7)
                font-weight: 300
                font-size: 32px

            .subtitle
                margin-top: 5px
                font-weight: 400
                font-size: 20px
                color: #989AAC

        .table-header
            grid-area: tableHeader
        .table-content
            grid-area: tableContent
            div:focus
                outline: none

        .table-header, .buyers.item
            overflow: hidden
            display: flex
            height: 50px

            .logo
                justify-content: center
                flex-direction: column
                display: flex
                img
                    object-fit: contain
                    height: 32px
                    width: 32px

            .basis
                padding: 0 5px 0 0
                min-width: 300px
                width: 300px
                flex: 1

            .buyer
                min-width: 150px
                padding: 0 5px
                width: 150px
                &.buyer--large
                    min-width: 200px
                    padding: 0 32px

            .protein
                min-width: 70px
                padding: 0 5px
                width: 70px
                &.protein--large
                    min-width: 100px
                    padding: 0 16px

            .cpt
                min-width: 80px
                padding: 0 5px
                width: 80px

            .km
                min-width: 70px
                padding: 0 5px
                width: 70px

            .rate
                min-width: 70px
                padding: 0 5px
                width: 70px

            .sell-price
                min-width: 95px
                padding: 0 5px
                width: 95px

            .make-order
                padding: 0 0 0 5px
                min-width: 70px
                width: 70px

            .sell-price, .protein
                span
                    +flex(row, center, flex-start)
                    margin-left: 3px
                    font-size: 8px
                    height: 15px

        .table-header
            border-bottom: 1px solid rgba(0, 0, 0, 0.1)
            align-items: center

            & div
                text-transform: uppercase
                color: rgba(0, 0, 0, 0.55)
                box-sizing: border-box
                letter-spacing: 0
                font-weight: 500
                font-size: 11px

        .buyers.item
            transition: all 0.2s ease
            cursor: pointer

            & > div
                letter-spacing: 0
                font-weight: 500
                font-size: 14px

            .buyer
                +flex(column, center, flex-start)
                box-sizing: border-box
                overflow: hidden

                .address
                    font-weight: 800

                .address, .name
                    color: rgba(0, 0, 0, 0.87)
                    text-overflow: ellipsis
                    white-space: nowrap
                    overflow: hidden

            .basis, .protein, .cpt, .km, .rate, .sell-price
                box-sizing: border-box
                align-items: center
                font-weight: 500
                display: flex
                height: 50px
                color: #000

            .make-order
                +flex(column, center, center)
                border-radius: 3px 0 0 3px
                transition: all 0.2s ease
                box-sizing: border-box
                height: 50px

                &.disabled
                    cursor: default
                    opacity: 0.4
                    &:hover
                        background: none

                &:hover
                    background-color: rgba(0, 0, 0, 0.05)

                .label
                    text-transform: uppercase
                    font-weight: 500
                    font-size: 10px
                    margin-top: 4px
                    color: #6C6F88

            &:hover
                background-color: #d1eff9
