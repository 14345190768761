// <label class="checkbox">
//   <input type="checkbox">
//   <span>Checkbox</span>
// </label>

.checkbox
    position: relative
    display: inline-block
    color: rgba(0, 0, 0, 0.87)
    cursor: pointer
    font-family: "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system
    font-size: 14px
    line-height: 18px
    
    > input
        appearance: none
        position: absolute
        z-index: -1
        left: -13px
        top: -13px
        display: block
        margin: 0
        border-radius: 50%
        width: 48px
        height: 48px
        background-color: rgba(0, 0, 0, 0.42)
        outline: none
        opacity: 0
        transform: scale(1)
        // -ms-transform: scale(0)
        transition: opacity 0.5s, transform 0.5s

        &:checked
            background-color: #5FC8ED
  
  
    &:active > input
        opacity: 1
        transform: scale(0)
        transition: opacity 0s, transform 0s
    
    >
        input:disabled
            opacity: 0
            
            + span
                color: rgba(0, 0, 0, 0.38)
                cursor: initial

        span
            font-size: 16px

        span::before
            content: ""
            display: inline-block
            margin-right: 8px
            border: solid 2px rgba(0, 0, 0, 0.42)
            border-radius: 2px
            width: 18px
            height: 18px
            vertical-align: -4px
            transition: border-color 0.5s, background-color 0.5s
        
        input
            &:checked + span::before
                border-color: #5FC8ED
                background-color: #5FC8ED
            &:active + span::before
                border-color: #5FC8ED
            &:checked:active + span::before
                border-color: transparent
                background-color: rgba(0, 0, 0, 0.42)
            &:disabled + span::before
                border-color: rgba(0, 0, 0, 0.26)
            &:checked:disabled + span::before
                border-color: transparent
                background-color: rgba(0, 0, 0, 0.26)
    
        span::after
            content: ""
            display: inline-block
            position: absolute
            top: 9px
            left: 11px
            width: 5px
            height: 10px
            border: solid 2px transparent
            border-left: none
            border-top: none
            transform: translate(-50%, -50%) rotate(45deg)
        //   -ms-transform: translate(5.5px, 2px) rotate(45deg)
          
        input:checked + span::after
            border-color: #fff
