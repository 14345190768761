
.ReactModal__Overlay
    background-color: rgba(15, 14, 14, 0.5) !important
    z-index: 9999 !important

.confirm-modal 
    background-color: #ffffff
    font-family: UniNeue
    border: none
    box-shadow: 0px -1px 4px rgba(0,0,0, 0.25)
    align-items: center
    border-radius: 4px
    padding: 16px 24px
    position: absolute
    top: 50%
    left: 50%    
    display: flex	
    flex-direction: column	
    &:focus
            outline: none            
    .close-btn
            cursor: pointer
            display: flex
            justify-content: flex-end
            margin-bottom: 14px            
            opacity: 0.6     
            		    
    .action-buttons            
            display: flex
            justify-content: center
            margin-bottom: 14px
    .content            
            margin-top: 24px
            margin-bottom: 24px

.confirm-modal .close-btn:hover
    opacity: 1
    
	

    
    