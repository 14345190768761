
.rc-slider
    height: 48px
    &-rail, &-track, &-step
        height: 6px
        top: 50%
        margin-top: -3px
    &-mark
        position: absolute
        left: 0
        top: calc(50% + 15px)
    &-dot
        position: absolute
        top: 50%
        margin-top: -4px
    &-handle
        width: 32px
        height: 32px
        margin-top: -16px
        background-color: rgba(95, 200, 231,0.5)
        border: none
        top: 50%
        left: 50%
        outline: none

        &:before
            content: ''
            width: 18px
            height: 18px
            position: absolute
            top: 50%
            left: 50%
            margin-left: -9px
            margin-top: -9px
            background-color: #5FC8ED
            border-radius: 18px
