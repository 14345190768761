.action
    +flexInline(row, center, center)
    background-color: $button-bg-color
    transition: all 0.2s ease
    text-transform: uppercase
    color: $button-tx-color
    letter-spacing: 1.1px
    margin-bottom: 16px
    border-radius: 4px
    font-weight: 500
    min-width: 170px
    font-size: 14px
    cursor: pointer
    height: 40px
    +disableTextSelection()

    &.action--small 
        font-size: 10px
        height: 22px
        min-width: 100px
    
    &.action--icon
        min-width: 24px
        min-height: 24px
        height: auto
        padding: 0
        margin: 0
    &.action--danger
        background-color: $danger-color
        &:hover
            background-color: lighten($danger-color, 10%)
    
    &:hover
        background-color: transparentize(#64C8EB, 0.2)
    
    &.disabled, &:disabled
        background-color: #F8F8F8 !important
        color: rgba(0,0,0, 0.2) !important
        &:hover
            background-color: #F8F8F8