a[href^="http://maps.google.com/maps"]
    display: none !important
a[href^="https://maps.google.com/maps"]
    display: none !important

.gmnoprint div, .gm-style-cc div
    display: none !important

.map
    overflow: hidden

    .static-map
        border-bottom: 1px solid rgba(0,0,0, 0.15)
        border-top: 1px solid rgba(0,0,0, 0.15)
        position: relative
        overflow: hidden
        height: 190px
        
        &.closed
            display: none

        .mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right
            display: none !important

    .distance
        padding: 10px 18px 0px 18px
        align-items: center
        margin-bottom: 10px
        display: flex
    
        .title
            +flex(column, center, space-between)
            height: 30px
            flex: 1

            .label
                color: rgba(0,0,0, 0.7)
                font-weight: 500
                font-size: 11px
            .text
                font-weight: 500
                font-size: 16px
                color: #000000

        .actions
            +flex(row, space-between, center)
            .action
                background-color: transparent
                transition: all 0.2 ease
                min-width: auto
                cursor: pointer
                margin: 0 3px
                height: 24px
                width: 24px
                
                &:hover
                    opacity: 0.7
