.date
    // z-index: 9992 !important
    position: relative
    &.date--invalid
        .input__message
            opacity: 0.7
        .react-date-picker__wrapper
            background-color: #FDF7C1 !important
    .react-date-picker
        display: block
        &__wrapper
            width: 100%
            padding: 16px 10px 4px 5px
            border: none
            border-bottom: 2px solid #D6D8E5
        &__calendar
            width: 100% !important
        &__calendar--open
            top: 48px !important
        &__inputGroup__input, &__inputGroup__leadingZero
            font-weight: 500 !important
            // min-width: 1.1em !important
        &__inputGroup__year
            min-width: 3em !important
    .react-calendar
        border: 1px solid rgba(0,0,0, 0.15)
        font-family: 'UniNeue' !important
        z-index: 9999999 !important
        border-radius: 0 0 5px 5px
        font-weight: 500
        border-top: none
        &__navigation
            border-bottom: 1px solid #D6D8E5
            &__label
                // text-shadow: 0 0 2px rgba(0,0,0, 0.2)
                text-transform: uppercase
                font-weight: 500
                font-size: 13px
                color: #5FC8ED
            &__arrow
                min-width: 36px

                &:first-of-type
                    border-right: 1px solid #D6D8E5
                &:last-of-type
                    border-left: 1px solid #D6D8E5

        &__tile, &__month-view__weekdays
            // background: linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 100%), linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)
            padding: 1em 0.5em
            color: #999999
        &__tile--active
            background: #5FC8ED !important
            color: #fff !important
            &:hover
                background: darken(#5FC8ED, 30%)
        &__navigation
            margin-bottom: 0
        &__month-view__days__day
            transition: all 0.2s ease
            padding: 0.4em 0.5em
            border-radius: 3px
            font-weight: 500
            color: #1B1B1B

            &:hover
                background: rgba(0,0,0, 0.15) !important

.rounded.date
    .label
        margin: 5px 8px 0 8px
        position: absolute
        font-weight: 500
        font-size: 11px
        z-index: 2

    .react-date-picker
        &__wrapper
            padding: 16px 10px 4px 5px
            border: 1px solid #D6D8E5
            background-color: #fff
            border-radius: 6px
            width: 100%