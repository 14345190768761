.profile.widget
    +flex(row, center, center)
    font-family: 'UniNeue'
    height: 34px

    .avatar
        border: 1px solid rgba(0,0,0, 0.1)
        +flex(row, center, center)
        background-color: #EEE
        border-radius: 50%
        overflow: hidden
        height: 44px
        width: 44px

        &.empty img
            margin-right: -4px
            margin-top: -4px
            height: 28px
            width: auto
        
        img
            height: 44px
            width: 44px

    .user
        margin-left: 8px

        .name
            font-weight: 800
            font-size: 16px
            color: #2E3043
        
        .role
            color: transparentize(#2E3043, 0.3)
            font-size: 14px