.profile.preview
    display: flex
    padding: 40px 2.5%
    height: 100%
    width: 95%

    @media (min-width: 768px)
        padding: 40px 2.5%
        width: 95%

    @media (min-width: 1367px)
        padding: 40px 7.5%
        width: 85%

    .content .items
        .item
            margin: 20px 0

        .row
            margin-top: 15px

            .column
                margin-right: 15px
                flex: none

.profile.form.docs
    .alert.panel
        width: 372px

.profile.preview.docs
    background-color: #F1F2F5

    & > .content
        margin-left: 198px

        .column
            flex: initial !important
            width: 450px

    .action
        margin-top: 50px
        max-width: 200px

    .alert.panel
        margin-top: 20px
        max-width: auto
        width: 650px

        .action
            text-decoration: none
            margin-left: 10px
            min-width: auto
            width: 120px
            margin: 0

    .alert.panel.verified .icon
        border: none
        height: 50px
        width: 50px

    .alert.panel
        background-color: #fff

.profile.preview.base
    .hover-popup > .action
        margin-bottom: 0
        max-width: none !important
    .action
        max-width: 200px

    .columns
        display: flex

        .photo
            +flex(column, center, center)

            margin-right: 70px
            width: 128px

            .avatar
                border: 1px solid rgba(0, 0, 0, 0.1)

                +flex(row, center, center)

                border-radius: 50%
                overflow: hidden
                height: 128px
                width: 128px

                img
                    width: 128px
                    height: auto

        .content
            // flex: 1
            .column
                width: 450px

// Refactored styles

.profile
    .label-text
        color: #2E3043
    &__title
        margin-bottom: 32px
    &__subtitle
        font-size: 14px
        line-height: 19px
        letter-spacing: 1px
        text-transform: uppercase
    &__info
        .info-label:not(:last-child)
            margin-bottom: 24px

    &__photo-container
        position: relative
        display: flex
        flex-direction: column
        margin-top: 72px
    &__photo
        width: 128px
        border-radius: 128px
        object-fit: cover
    &__verified-icon
        position: absolute
        top: 100px
        left: 96px
        width: 26px
        height: 26px
    &__edit-button
        margin-top: 26px

    &.docs
        .info-label
            margin-bottom: 20px
        .label-text
            margin-bottom: 26px
        .alert.panel
            margin-bottom: 20px
    &__org-docs
        border-top: 1px dashed rgba(#000, 0.2)
        padding-top: 44px
        margin-top: 24px
