.table-overlay
    position: relative
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: stretch
    &.table-overlay--fill
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%

.table-scroll
    overflow: auto
.table
    &__action
        padding: 4px
        border-radius: 4px
        &:hover
            background: rgba(0,0,0,0.05)

    &.table--head
        border-bottom: 1px solid rgba(#000, 0.1)
        margin-bottom: -51px
        flex-direction: row
        align-items: center
        min-height: 51px
        background: #fff
        display: flex
        z-index: 10
        div
            font-family: 'UniNeue', Helvetica, sans-serif
            color: rgba(#000, 0.55)
            text-transform: uppercase
            box-sizing: border-box
            white-space: nowrap
            font-weight: 500
            text-align: left
            font-size: 11px
            padding: 10px
            &:first-child
                padding-left: 10px
                padding-right: 0
            &:last-child
                padding-left: 0
                padding-right: 10px
    thead
        tr
            height: 51px
            th
                font-family: 'UniNeue', Helvetica, sans-serif
                border-bottom: 1px solid rgba(#000, 0.1)
                color: rgba(#000, 0.55)
                text-transform: uppercase
                font-weight: 500
                text-align: left
                font-size: 11px

    th, td
        padding: 10px
        &:first-child
            padding-left: 10px
            padding-right: 0
        &:last-child
            padding-left: 0
            padding-right: 10px

    tbody
        tr
            height: 50px
            transition: 0.25s

            &:hover
                background: #D1EFFD
        td
            font-size: 14px
            font-weight: 500
