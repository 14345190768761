/*---------------------------------------------------------------------------*
 * SF UI Display
 *---------------------------------------------------------------------------

/** Black
@font-face
    font-family: "SF Display"
    font-weight: 900
    src: url("../fonts/SF-UI-Display-Black.otf")

/** Bold
@font-face
    font-family: "SF Display"
    font-weight: 700
    src: url("../fonts/SF-UI-Display-Bold.otf")

/** Heavy
@font-face
    font-family: "SF Display"
    font-weight: 800
    src: url("../fonts/SF-UI-Display-Heavy.otf")

/** Light
@font-face
    font-family: "SF Display"
    font-weight: 200
    src: url("../fonts/SF-UI-Display-Light.otf")

/** Medium
@font-face
    font-family: "SF Display"
    font-weight: 500
    src: url("../fonts/SF-UI-Display-Medium.otf")

/** Regular
@font-face
    font-family: "SF Display"
    font-weight: 400
    src: url("../fonts/SF-UI-Display-Regular.otf")

/** Semibold
@font-face
    font-family: "SF Display"
    font-weight: 600
    src: url("../fonts/SF-UI-Display-Semibold.otf")

/** Thin
@font-face
    font-family: "SF Display"
    font-weight: 300
    src: url("../fonts/SF-UI-Display-Thin.otf")

/** Ultralight
@font-face
    font-family: "SF Display"
    font-weight: 100
    src: url("../fonts/SF-UI-Display-Ultralight.otf")

/*********** UniNeue **************
@font-face
    font-family: "UniNeue"
    src: url("../fonts/UniNeue-Bold.ttf")
    font-weight: bold

@font-face
    font-family: "UniNeue"
    src: url("../fonts/UniNeue-Normal.ttf")
    font-weight: normal

@font-face
    font-family: "UniNeue"
    src: url("../fonts/UniNeue-Semibold.ttf")
    font-weight: 500
