.avatar
    position: relative
    > .check
        width: 18px
        height: 18px
        position: absolute
        z-index: 100
        bottom: -7px
        left: 24px
        background: #64C8EB
        border-radius: 18px