.input.company, .input.bank
  font-family: 'UniNeue'

  .input.rounded
    &.opened
      // border-radius: 6px 6px 0 0
      // border-bottom: none
  
  .menu
    border: 1px solid rgba(0,0,0, 0.2)
    // border-radius: 0 0 6px 6px
    background-color: #ffffff
    border-radius: 6px
    position: absolute
    z-index: 9999

    margin-top: 3px

    flex-direction: column
    width: calc(100% - 2px)
    min-height: 125px
    display: flex
  
    .empty
      +flex(row, center, center)
      color: rgba(0,0,0, 0.7)
      letter-spacing: 0
      font-weight: 500
      font-size: 16px
      height: 50px
      width: 100%
    
    .item
      transition: all 0.2s ease
      color: rgba(0,0,0, 0.75)
      text-overflow: ellipsis
      white-space: nowrap
      overflow: hidden
      font-weight: 500
      padding: 4px 5px
      cursor: pointer
      font-size: 15px
      max-width: 100%
      width: 100%

      .name, .type
        color: rgba(0,0,0, 0.8)
        letter-spacing: 0
        font-weight: 500
        font-size: 15px

      .type
        margin-top: 3px

      .reg-info
        flex-direction: column
        margin-top: 3px
        display: flex

        .coracc, .bic
          color: rgba(0,0,0, 0.6)
          letter-spacing: 0
          font-weight: 500
          font-size: 14px

      .reg-codes
        +flex(row, flex-start, flex-start)
        margin-top: 3px

        .inn
          margin-left: 10px

        .inn, .ogrn
          color: rgba(0,0,0, 0.6)
          letter-spacing: 0
          font-weight: 500
          font-size: 14px

      &.selected
        background-color: rgba(0,0,0, 0.1)