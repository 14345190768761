$document-font-size: 14pt
$document-line-height: 1.0791666666666666
$document-header-1-size: 14pt
$document-header-2-size: 14pt
$document-subheader-size: 14pt

.privacy-policy-page
    font-size: $document-font-size
    max-height: 100vh
    overflow: auto
    &__document
        max-width: 800px
        margin: auto
        padding: 24px 16px
    h1
        font-size: 32px

    // These styles has been generated by google docs
    ul
        &.lst-kix_list_9-3, &.lst-kix_list_9-4, &.lst-kix_list_9-1, &.lst-kix_list_9-2, &.lst-kix_list_9-7, &.lst-kix_list_9-8, &.lst-kix_list_9-5
            list-style-type: none

    .lst-kix_list_5-0 > li
        counter-increment: lst-ctn-kix_list_5-0

    ul.lst-kix_list_9-6
        list-style-type: none

    ol.lst-kix_list_1-5.start
        counter-reset: lst-ctn-kix_list_1-5 0

    ul.lst-kix_list_9-0
        list-style-type: none

    ol.lst-kix_list_5-3.start
        counter-reset: lst-ctn-kix_list_5-3 0

    .lst-kix_list_1-2 > li
        counter-increment: lst-ctn-kix_list_1-2

    .lst-kix_list_5-2 > li
        counter-increment: lst-ctn-kix_list_5-2

    .lst-kix_list_5-0 > li:before
        content: "" counter(lst-ctn-kix_list_5-0, decimal) ". "
        font-weight: bold

    .lst-kix_list_5-4 > li
        counter-increment: lst-ctn-kix_list_5-4

    .lst-kix_list_1-4 > li
        counter-increment: lst-ctn-kix_list_1-4

    ol.lst-kix_list_1-6.start
        counter-reset: lst-ctn-kix_list_1-6 0

    .lst-kix_list_5-3 > li:before
        content: "" counter(lst-ctn-kix_list_5-0, decimal) "." counter(lst-ctn-kix_list_5-1, decimal) "." counter(lst-ctn-kix_list_5-2, decimal) "." counter(lst-ctn-kix_list_5-3, decimal) ". "

    .lst-kix_list_5-2 > li:before
        content: "" counter(lst-ctn-kix_list_5-0, decimal) "." counter(lst-ctn-kix_list_5-1, decimal) "." counter(lst-ctn-kix_list_5-2, decimal) ". "

    .lst-kix_list_5-1 > li:before
        content: "" counter(lst-ctn-kix_list_5-0, decimal) "." counter(lst-ctn-kix_list_5-1, decimal) ". "

    .lst-kix_list_5-7 > li:before
        content: "" counter(lst-ctn-kix_list_5-0, decimal) "." counter(lst-ctn-kix_list_5-1, decimal) "." counter(lst-ctn-kix_list_5-2, decimal) "." counter(lst-ctn-kix_list_5-3, decimal) "." counter(lst-ctn-kix_list_5-4, decimal) "." counter(lst-ctn-kix_list_5-5, decimal) "." counter(lst-ctn-kix_list_5-6, decimal) "." counter(lst-ctn-kix_list_5-7, decimal) ". "

    ul
        &.lst-kix_list_8-4, &.lst-kix_list_8-5
            list-style-type: none

    .lst-kix_list_5-6 > li:before
        content: "" counter(lst-ctn-kix_list_5-0, decimal) "." counter(lst-ctn-kix_list_5-1, decimal) "." counter(lst-ctn-kix_list_5-2, decimal) "." counter(lst-ctn-kix_list_5-3, decimal) "." counter(lst-ctn-kix_list_5-4, decimal) "." counter(lst-ctn-kix_list_5-5, decimal) "." counter(lst-ctn-kix_list_5-6, decimal) ". "

    .lst-kix_list_5-8 > li:before
        content: "" counter(lst-ctn-kix_list_5-0, decimal) "." counter(lst-ctn-kix_list_5-1, decimal) "." counter(lst-ctn-kix_list_5-2, decimal) "." counter(lst-ctn-kix_list_5-3, decimal) "." counter(lst-ctn-kix_list_5-4, decimal) "." counter(lst-ctn-kix_list_5-5, decimal) "." counter(lst-ctn-kix_list_5-6, decimal) "." counter(lst-ctn-kix_list_5-7, decimal) "." counter(lst-ctn-kix_list_5-8, decimal) ". "

    ul
        &.lst-kix_list_8-2, &.lst-kix_list_8-3, &.lst-kix_list_8-8, &.lst-kix_list_8-6, &.lst-kix_list_8-7
            list-style-type: none

    .lst-kix_list_5-4 > li:before
        content: "" counter(lst-ctn-kix_list_5-0, decimal) "." counter(lst-ctn-kix_list_5-1, decimal) "." counter(lst-ctn-kix_list_5-2, decimal) "." counter(lst-ctn-kix_list_5-3, decimal) "." counter(lst-ctn-kix_list_5-4, decimal) ". "

    .lst-kix_list_5-5 > li:before
        content: "" counter(lst-ctn-kix_list_5-0, decimal) "." counter(lst-ctn-kix_list_5-1, decimal) "." counter(lst-ctn-kix_list_5-2, decimal) "." counter(lst-ctn-kix_list_5-3, decimal) "." counter(lst-ctn-kix_list_5-4, decimal) "." counter(lst-ctn-kix_list_5-5, decimal) ". "

    ul
        &.lst-kix_list_8-0, &.lst-kix_list_8-1
            list-style-type: none

    ol.lst-kix_list_1-0.start
        counter-reset: lst-ctn-kix_list_1-0 0

    .lst-kix_list_6-1 > li:before
        content: "\002022  "

    .lst-kix_list_6-3 > li:before, .lst-kix_list_6-0 > li:before
        content: "\0025cf  "

    .lst-kix_list_6-4 > li:before
        content: "o  "

    .lst-kix_list_6-2 > li:before, .lst-kix_list_6-8 > li:before, .lst-kix_list_6-5 > li:before
        content: "\0025aa  "

    .lst-kix_list_6-7 > li:before
        content: "o  "

    .lst-kix_list_6-6 > li:before
        content: "\0025cf  "

    ol
        &.lst-kix_list_1-3, &.lst-kix_list_1-4
            list-style-type: none

    .lst-kix_list_2-7 > li:before
        content: "\0025aa  "

    ol.lst-kix_list_1-5
        list-style-type: none

    .lst-kix_list_7-4 > li:before
        content: "o  "

    .lst-kix_list_7-6 > li:before
        content: "\0025cf  "

    ol
        &.lst-kix_list_1-6, &.lst-kix_list_1-0
            list-style-type: none

    .lst-kix_list_2-5 > li:before
        content: "\0025aa  "

    ol
        &.lst-kix_list_1-1, &.lst-kix_list_1-2
            list-style-type: none

    .lst-kix_list_7-2 > li:before
        content: "\0025aa  "

    ul
        &.lst-kix_list_3-7, &.lst-kix_list_3-8, &.lst-kix_list_3-1, &.lst-kix_list_3-2
            list-style-type: none

    .lst-kix_list_5-7 > li
        counter-increment: lst-ctn-kix_list_5-7

    .lst-kix_list_7-8 > li:before
        content: "\0025aa  "

    ul.lst-kix_list_3-0, ol.lst-kix_list_1-7, ul.lst-kix_list_3-5, ol.lst-kix_list_1-8
        list-style-type: none

    ul
        &.lst-kix_list_3-6, &.lst-kix_list_3-3, &.lst-kix_list_3-4
            list-style-type: none

    .lst-kix_list_4-1 > li:before
        content: "o  "

    .lst-kix_list_9-2 > li:before
        content: "\0025aa  "

    .lst-kix_list_4-3 > li:before
        content: "\0025cf  "

    .lst-kix_list_4-5 > li:before
        content: "\0025aa  "

    ol.lst-kix_list_5-7.start
        counter-reset: lst-ctn-kix_list_5-7 0

    .lst-kix_list_1-8 > li
        counter-increment: lst-ctn-kix_list_1-8

    ol.lst-kix_list_1-4.start
        counter-reset: lst-ctn-kix_list_1-4 0

    .lst-kix_list_5-5 > li
        counter-increment: lst-ctn-kix_list_5-5

    ol.lst-kix_list_1-1.start
        counter-reset: lst-ctn-kix_list_1-1 0

    .lst-kix_list_9-0 > li:before, .lst-kix_list_9-6 > li:before
        content: "\0025cf  "

    .lst-kix_list_9-4 > li:before
        content: "o  "

    ol.lst-kix_list_1-3.start
        counter-reset: lst-ctn-kix_list_1-3 0

    ul.lst-kix_list_2-8
        list-style-type: none

    ol.lst-kix_list_1-2.start
        counter-reset: lst-ctn-kix_list_1-2 0

    ul
        &.lst-kix_list_2-2, &.lst-kix_list_2-3, &.lst-kix_list_2-0, &.lst-kix_list_2-1
            list-style-type: none

    .lst-kix_list_9-8 > li:before
        content: "\0025aa  "

    ul.lst-kix_list_2-6
        list-style-type: none

    .lst-kix_list_1-1 > li:before
        content: "" counter(lst-ctn-kix_list_1-1, lower-latin) ". "

    ul
        &.lst-kix_list_2-7, &.lst-kix_list_2-4, &.lst-kix_list_2-5
            list-style-type: none

    .lst-kix_list_1-3 > li:before
        content: "" counter(lst-ctn-kix_list_1-3, decimal) ". "

    .lst-kix_list_1-7 > li:before
        content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". "

    ol.lst-kix_list_5-8.start
        counter-reset: lst-ctn-kix_list_5-8 0

    .lst-kix_list_1-3 > li
        counter-increment: lst-ctn-kix_list_1-3

    .lst-kix_list_1-5 > li:before
        content: "" counter(lst-ctn-kix_list_1-5, lower-roman) ". "

    .lst-kix_list_5-6 > li
        counter-increment: lst-ctn-kix_list_5-6

    .lst-kix_list_2-1 > li:before
        content: "o  "

    .lst-kix_list_2-3 > li:before
        content: "\0025aa  "

    .lst-kix_list_5-1 > li
        counter-increment: lst-ctn-kix_list_5-1

    .lst-kix_list_1-1 > li
        counter-increment: lst-ctn-kix_list_1-1

    .lst-kix_list_3-0 > li:before
        content: "\0025cf  "

    .lst-kix_list_3-1 > li:before
        content: "o  "

    .lst-kix_list_3-2 > li:before
        content: "\0025aa  "

    .lst-kix_list_8-1 > li:before
        content: "\0025cf  "

    ol.lst-kix_list_1-8.start
        counter-reset: lst-ctn-kix_list_1-8 0

    .lst-kix_list_8-2 > li:before, .lst-kix_list_3-5 > li:before, .lst-kix_list_3-4 > li:before, .lst-kix_list_3-3 > li:before
        content: "\0025aa  "

    .lst-kix_list_8-0 > li:before
        content: "\0025cf  "

    .lst-kix_list_8-7 > li:before
        content: "o  "

    .lst-kix_list_3-8 > li:before, .lst-kix_list_8-5 > li:before
        content: "\0025aa  "

    .lst-kix_list_8-6 > li:before, .lst-kix_list_8-3 > li:before
        content: "\0025cf  "

    .lst-kix_list_3-6 > li:before, .lst-kix_list_3-7 > li:before
        content: "\0025aa  "

    .lst-kix_list_8-4 > li:before
        content: "o  "

    ol.lst-kix_list_5-0.start
        counter-reset: lst-ctn-kix_list_5-0 0

    .lst-kix_list_8-8 > li:before, .lst-kix_list_4-8 > li:before
        content: "\0025aa  "

    .lst-kix_list_4-7 > li:before
        content: "o  "

    ul.lst-kix_list_4-8
        list-style-type: none

    ol.lst-kix_list_5-6.start
        counter-reset: lst-ctn-kix_list_5-6 0

    ul
        &.lst-kix_list_4-6, &.lst-kix_list_4-7, &.lst-kix_list_4-0, &.lst-kix_list_4-1, &.lst-kix_list_4-4, &.lst-kix_list_4-5, &.lst-kix_list_4-2, &.lst-kix_list_4-3
            list-style-type: none

    ol.lst-kix_list_5-5.start
        counter-reset: lst-ctn-kix_list_5-5 0

    .lst-kix_list_7-0 > li:before
        content: "\0025cf  "

    ol.lst-kix_list_5-0
        list-style-type: none

    .lst-kix_list_2-6 > li:before
        content: "\0025aa  "

    ol
        &.lst-kix_list_5-1, &.lst-kix_list_5-2
            list-style-type: none

    .lst-kix_list_2-4 > li:before, .lst-kix_list_2-8 > li:before
        content: "\0025aa  "

    .lst-kix_list_7-1 > li:before
        content: "o  "

    .lst-kix_list_7-5 > li:before
        content: "\0025aa  "

    ol.lst-kix_list_5-4.start
        counter-reset: lst-ctn-kix_list_5-4 0

    .lst-kix_list_7-3 > li:before
        content: "\0025cf  "

    ul
        &.lst-kix_list_7-5, &.lst-kix_list_7-6, &.lst-kix_list_7-3, &.lst-kix_list_7-4
            list-style-type: none

    ol.lst-kix_list_5-1.start
        counter-reset: lst-ctn-kix_list_5-1 0

    ul
        &.lst-kix_list_7-7, &.lst-kix_list_7-8
            list-style-type: none

    ol
        &.lst-kix_list_5-7, &.lst-kix_list_5-8, &.lst-kix_list_5-3
            list-style-type: none

    ul.lst-kix_list_7-1, ol.lst-kix_list_5-4, ul.lst-kix_list_7-2
        list-style-type: none

    .lst-kix_list_1-7 > li
        counter-increment: lst-ctn-kix_list_1-7

    ol
        &.lst-kix_list_5-5, &.lst-kix_list_5-6
            list-style-type: none

    ul.lst-kix_list_7-0
        list-style-type: none

    .lst-kix_list_7-7 > li:before
        content: "o  "

    .lst-kix_list_5-8 > li
        counter-increment: lst-ctn-kix_list_5-8

    .lst-kix_list_4-0 > li:before
        content: "\0025cf  "

    ol.lst-kix_list_1-7.start
        counter-reset: lst-ctn-kix_list_1-7 0

    .lst-kix_list_4-4 > li:before
        content: "o  "

    .lst-kix_list_1-5 > li
        counter-increment: lst-ctn-kix_list_1-5

    .lst-kix_list_4-2 > li:before
        content: "\0025aa  "

    .lst-kix_list_4-6 > li:before, .lst-kix_list_9-3 > li:before
        content: "\0025cf  "

    .lst-kix_list_9-1 > li:before, .lst-kix_list_9-7 > li:before
        content: "o  "

    .lst-kix_list_9-5 > li:before
        content: "\0025aa  "

    ul
        &.lst-kix_list_6-6, &.lst-kix_list_6-7
            list-style-type: none

    .lst-kix_list_5-3 > li
        counter-increment: lst-ctn-kix_list_5-3

    ul
        &.lst-kix_list_6-4, &.lst-kix_list_6-5, &.lst-kix_list_6-8
            list-style-type: none

    .lst-kix_list_1-0 > li:before
        content: "" counter(lst-ctn-kix_list_1-0, decimal) ". "

    ul
        &.lst-kix_list_6-2, &.lst-kix_list_6-3
            list-style-type: none

    .lst-kix_list_1-2 > li:before
        content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ". "

    ul
        &.lst-kix_list_6-0, &.lst-kix_list_6-1
            list-style-type: none

    .lst-kix_list_1-4 > li:before
        content: "" counter(lst-ctn-kix_list_1-4, lower-latin) ". "

    .lst-kix_list_1-0 > li
        counter-increment: lst-ctn-kix_list_1-0

    .lst-kix_list_1-6 > li
        counter-increment: lst-ctn-kix_list_1-6

    // &:before
    //     content: "" counter(lst-ctn-kix_list_1-6, decimal) ". "

    .lst-kix_list_2-0 > li:before
        content: "\0025cf  "

    .lst-kix_list_1-8 > li:before
        content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". "

    .lst-kix_list_2-2 > li:before
        content: "\0025aa  "

    ol
        &.lst-kix_list_5-2.start
            counter-reset: lst-ctn-kix_list_5-2 0

            margin: 0
            padding: 0

    table
        td, th
            padding: 0

    .c9
        padding-top: 0pt
        border-left-style: solid
        padding-left: $document-font-size
        border-left-color: #4472c4
        padding-bottom: 0pt
        line-height: $document-line-height
        border-left-width: 1.5pt
        orphans: 2
        widows: 2
        text-align: left

    .c16
        -webkit-text-decoration-skip: none
        color: #0563c1
        font-weight: 400
        text-decoration: underline
        vertical-align: baseline
        text-decoration-skip-ink: none
        font-size: $document-font-size
        font-style: normal

    .c2
        margin-left: 0pt
        padding-top: 0pt
        list-style-position: inside
        text-indent: 45pt
        padding-bottom: 8pt
        line-height: $document-line-height
        orphans: 2
        widows: 2
        text-align: justify

    .c3
        margin-left: 0pt
        padding-top: 0pt
        list-style-position: inside
        text-indent: 45pt
        padding-bottom: 0pt
        line-height: $document-line-height
        orphans: 2
        widows: 2
        text-align: justify

    .c0
        padding-top: 0pt
        text-indent: -36pt
        padding-bottom: 0pt
        line-height: $document-line-height
        orphans: 2
        widows: 2
        text-align: justify
        height: $document-font-size

    .c4
        margin-left: 18pt
        padding-top: 0pt
        padding-left: -3.8pt
        padding-bottom: 0pt
        line-height: $document-line-height
        orphans: 2
        widows: 2
        text-align: justify

    .c20
        color: #000000
        font-weight: 400
        text-decoration: none
        vertical-align: baseline
        font-size: $document-font-size

        font-style: italic

    .c14
        color: #000000
        font-weight: 700
        text-decoration: none
        vertical-align: baseline
        font-size: $document-font-size

        font-style: normal

    .c21
        color: #2f5496
        font-weight: 400
        text-decoration: none
        vertical-align: baseline
        font-size: 13pt

        font-style: normal

    .c18
        color: #000000
        font-weight: 700
        text-decoration: none
        vertical-align: baseline
        font-size: $document-header-1-size

        font-style: normal

    .c6
        color: #000000
        font-weight: 700
        text-decoration: none
        vertical-align: baseline
        font-size: $document-header-2-size

        font-style: normal

    .c1
        color: #000000
        font-weight: 400
        text-decoration: none
        vertical-align: baseline
        font-size: $document-subheader-size

        font-style: normal

    .c7
        padding-top: 0pt
        padding-bottom: 8pt
        line-height: 1.0
        orphans: 2
        widows: 2
        text-align: justify

    .c22
        padding-top: 0pt
        padding-bottom: 0pt
        line-height: 1.0
        orphans: 2
        widows: 2
        text-align: left

    .c10
        padding-top: 0pt
        padding-bottom: 8pt
        line-height: $document-line-height
        orphans: 2
        widows: 2
        text-align: justify

    .c12
        padding-top: 0pt
        padding-bottom: 0pt
        line-height: $document-line-height
        orphans: 2
        widows: 2
        text-align: justify

    .c8
        background-color: #ffffff
        max-width: 481.9pt
        padding: 56.7pt 42.5pt 56.7pt 70.9pt

    .c5
        padding: 0
        margin: 0
        margin-bottom: 16px

    .c15
        margin-left: 18pt
        padding-left: 17.5pt

    .c17
        color: inherit
        text-decoration: inherit

    .c11
        margin-left: 18pt
        padding-left: -3.8pt

    .c19
        margin-left: 14.2pt

    .c23
        margin-left: 18pt

    .c13
        height: $document-font-size

    .title
        padding-top: 24pt
        color: #000000
        font-weight: 700
        font-size: 36pt
        padding-bottom: 6pt

        line-height: $document-line-height
        page-break-after: avoid
        orphans: 2
        widows: 2
        text-align: left

    .subtitle
        padding-top: 18pt
        color: #666666
        font-size: 24pt
        padding-bottom: 4pt
        font-family: "Georgia"
        line-height: $document-line-height
        page-break-after: avoid
        font-style: italic
        orphans: 2
        widows: 2
        text-align: left

    li
        color: #000000
        font-size: $document-font-size


    p
        margin: 0
        color: #000000
        font-size: $document-font-size


    h2
        padding-top: 4pt
        color: #404040
        font-size: $document-font-size
        padding-bottom: 0pt

        line-height: 1.0
        page-break-after: avoid
        orphans: 2
        widows: 2
        text-align: left

    h3
        padding-top: $document-font-size
        color: #000000
        font-weight: 700
        font-size: $document-font-size
        padding-bottom: 4pt

        line-height: $document-line-height
        page-break-after: avoid
        orphans: 2
        widows: 2
        text-align: left

    h4
        padding-top: 12pt
        color: #000000
        font-weight: 700
        font-size: 12pt
        padding-bottom: 2pt

        line-height: $document-line-height
        page-break-after: avoid
        orphans: 2
        widows: 2
        text-align: left

    h5
        padding-top: $document-font-size
        color: #000000
        font-weight: 700
        font-size: $document-font-size
        padding-bottom: 2pt

        line-height: $document-line-height
        page-break-after: avoid
        orphans: 2
        widows: 2
        text-align: left

    h6
        padding-top: 10pt
        color: #000000
        font-weight: 700
        font-size: 10pt
        padding-bottom: 2pt

        line-height: $document-line-height
        page-break-after: avoid
        orphans: 2
        widows: 2
        text-align: left
