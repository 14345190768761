.modal
    background-color: rgba(0,0,0, 0.2)
    cursor: pointer
    display: flex
    height: 100%
    width: 100%

    .modal-overlay
        position: absolute
        height: 100%
        width: 100%
        z-index: 10
        left: 0
        top: 0

.modal.sended-car
    background-color: #fff !important
    +flex(row, center, center)
    position: absolute
    z-index: 1000