=menuItem($active_border)
    color: transparentize(#2E3043, 0.35)
    transition: all 0.25s ease
    text-transform: uppercase
    text-decoration: none
    letter-spacing: 0
    font-weight: 800
    cursor: pointer

    @if $active_border == "left"
        padding: 10px 45px
    @else
        padding: 19px 10px

    &.active
        border-#{$active_border}: 4px solid #2E3043
        color: #2E3043

    &:hover
        background-color: transparentize(#2E3043, 0.85)
        color: #2E3043

.menu
    transition: all 0.3s ease
    font-family: "UniNeue"
    overflow: hidden
    z-index: 9999

    .fill
        flex: 1

    &.navbar
        +flex(row, flex-start, center)

        background-color: #FFF
        padding-left: 16px
        font-size: 16px
        height: 56px

        .list
            flex-direction: row
            margin-left: 10px
            display: flex
            width: auto
            padding: 0

            a.item
                +menuItem(bottom)

                font-size: 14px

        .profile.widget
            margin-right: 20px

            .avatar
                min-height: 44px
                min-width: 44px

        .logo
            img
                width: 130px
                height: auto

        .logout
            border-top: 1px solid rgba(0, 0, 0, 0.12)

            +menuItem(bottom)
            +flex(row, flex-start, center)

            border-top: none
            font-size: 14px

            img
                margin-right: 10px

    &.sidebar
        flex-direction: column
        background-color: #FFF
        overflow: hidden
        font-size: 16px
        display: flex
        width: 256px

        .profile.widget
            margin: 16px

        .logo
            border-top: 1px solid rgba(0, 0, 0, 0.15)

            +flex(row, flex-start, center)

            width: calc(100% - 32px)
            padding: 16px 16px 16px 20px

            img
                width: 130px
                height: auto

        .list
            flex-direction: column
            margin-top: 15px
            display: flex
            width: 100%
            padding: 0

            a.item
                +menuItem(left)

        .logout
            border-top: 1px solid rgba(0, 0, 0, 0.12)

            +menuItem(left)
            +flex(row, flex-start, center)

            padding: 10px 0
            width: 250px

            img
                margin-left: 45px
                margin-right: 10px

// Change views with transitions
@media screen and (max-width: 2000px)
    .menu.navbar
        height: 56px
    .menu.sidebar
        width: 0px

@media screen and (min-width: 2000px)
    .menu.navbar
        height: 0px
    .menu.sidebar
        width: 256px
