=flex($direction, $justify, $align)
    flex-direction: $direction
    justify-content: $justify
    align-items: $align
    display: flex
=flexInline ($direction, $justify, $align) 
    flex-direction: $direction
    justify-content: $justify
    align-items: $align
    display: inline-flex

=disableTextSelection
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none

$button-bg-color: #64C8EB
$button-tx-color: #ffffff
$danger-color: #e84d5a
$widget-bg-color: #2E3043
$menu-tx-color: #fff
