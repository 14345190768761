.sidebar.create
    .fill-profile
        +flex(column, center, center)
        margin-top: 40px

        svg
            width: 120px
            height: auto

        .title
            margin: 25px 16px 16px 16px
            color: rgba(0,0,0, 0.7)
            text-align: center
            margin-top: 15px
            font-weight: 500
            font-size: 20px
        
        .action
            text-decoration: none
            
    .form
        flex: 1

        .group.shipments
            border: 1px solid rgba(0, 0, 0, 0.15)
            padding: 8px 0px 5px 0
            border-radius: 8px
            margin-top: 10px

            .date
                .label
                    color: rgba(0, 0, 0, 0.6)
                    padding-left: 10px
                    font-size: 11px

                &:last-child .react-date-picker__calendar
                    margin-left: calc((-18rem + 32px) / 2) !important

                .react-date-picker
                    &__calendar
                        width: calc(18rem - 32px) !important
                        top: 40px !important

                        &-button
                            padding-left: 0px !important

                        & > .react-calendar
                            border-top: 1px solid rgba(0, 0, 0, 0.15) !important
                            border-radius: 5px !important

                    &__wrapper
                        padding: 0px 0px 0px 5px !important
                        border: none !important

                    svg g
                        stroke: #64C8EB !important

        .fields
            margin: 0px 16px 30px 16px
            flex-direction: column
            display: flex
            width: 268px

            .header
                color: #2e3043
                margin-bottom: 5px
                margin-top: 20px
                font-size: 14px
                font-weight: bold
                text-transform: uppercase
                letter-spacing: 1.4px

            .map
                margin: 0px -16px 0 -16px

            .row
                display: flex

                > div
                    flex: 1

            .row.loss
                .allowedLoss, .allowedLossUnit
                    flex: auto

                    .select__control:not(.select__control--is-focused)
                        .select__value-container
                            .label
                                font-size: 15px !important

                .allowedLoss
                    width: 45%

                    .select__indicators
                        display: none !important

                .allowedLossUnit
                    width: 55%

            .form-item.input, .form-item.select
                margin-top: 10px !important

    .order
        &-rate
            border: 1px solid rgba(0, 0, 0, 0.15)
            border-radius: 5px
            margin-top: 16px
            overflow: hidden

            hr
                background-color: #D6D8E5
                margin: 16px -10px
                border: none
                height: 1px

            .current
                padding: 9px 10px 9px 10px
                font-weight: 500
                cursor: pointer

                &.disabled
                    .value, .price
                        color: rgba(0, 0, 0, 0.5)

                .label
                    font-size: 11px
                    color: #6C6F88
                    opacity: 0.7

                .no-vat
                    margin-left: 8px
                    font-size: 11px
                    color: #6C6F88

                .is-recommended
                    margin-left: 8px
                    font-size: 11px
                    color: #64C8EB

            .inner
                padding: 8px 10px 8px 10px

                .input.rounded
                    margin-top: 18px

                .total
                    .label
                        color: rgba(0, 0, 0, 0.65)
                        font-weight: 500
                        font-size: 15px

                    .price
                        color: rgba(0, 0, 0, 0.87)
                        font-weight: 500
                        font-size: 18px
                        margin-top: 5px

                .options
                    .checkbox
                        margin-bottom: 16px
                        display: block

                .slider
                    margin: 0 10px

    .form-item.input.material input
        width: 100%

    .submit
        +flex(row, center, center)

        text-transform: uppercase
        background-color: #64C8EB
        transition: 0.2s ease
        letter-spacing: 0
        font-weight: 800
        cursor: pointer
        font-size: 16px
        color: #ffffff
        height: 50px
        &.delete 
            background-color: #9D5050
            &:hover
                background-color: darken(#9D5050,10)
        &.disabled
            color: rgba(0, 0, 0, 0.3)
            background-color: #FFF

            &:hover
                background-color: #FFF

        &:hover
            background-color: darken(#64C8EB, 30%)