body
    overflow: hidden
    height: 100vh

.root
    height: 100%
    width: 100%

.content-layout-grid
    grid-template-areas: "topMenu topMenu topMenu" "leftMenu page actionSidebar"
    display: grid
    height: 100%

    grid-template-columns: auto 1fr auto
    grid-template-rows: auto 1fr

    .navbar.menu
        grid-area: topMenu
    .sidebar.menu
        grid-area: leftMenu
    .panel-page
        grid-area: page
    .sidebar.right
        grid-area: actionSidebar

.content-layout-grid .panel-page
    & > div, & > div > .page
        height: 100%