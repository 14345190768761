.transports.page .map-zone
    height: 100%

    .mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right
        display: none !important

    .mapboxgl-marker
        // transition: all 0.2s ease
        display: flex

        &:hover
            z-index: 10 !important

            .cluster-marker-content:before
                transform: scale(8)

            .marker-content:before
                transform: scale(5)

        .cluster-marker-content.hovered:before
            transform: scale(8)

        .marker-content svg
            width: 17px
            height: 17px

        .marker-content.hovered:before
            transform: scale(5)

        .marker-content, .cluster-marker-content
            border: 4px solid rgb(255, 255, 255)

            +flex(row, center, center)

            transition: all 0.2s ease
            background-color: #A5A7BB
            position: relative
            border-radius: 50%
            flex: 1

            +disableTextSelection

            &:before
                background-color: transparentize(#64C8EB, 0.7)
                transition: all 0.15s ease
                left: calc(50% - 5px)
                top: calc(50% - 5px)
                border-radius: 50%
                position: absolute
                height: 10px
                z-index: -10
                width: 10px
                content: ""

            .tooltip
                flex-direction: column
                padding-bottom: 40px
                border-radius: 5px
                position: absolute
                overflow: hidden
                display: none
                z-index: 9999
                height: 190px
                width: 300px

                .executor.transport.item
                    box-shadow: 0px 0px 4px rgba(103, 116, 150, 0.246971)
                    position: relative
                    border-radius: 5px
                    overflow: hidden

                    .content-info
                        min-height: auto
                        height: auto

                        .transport-actions .item
                            height: 64px

            &:hover .tooltip, .tooltip:hover
                justify-content: flex-end
                flex-direction: column
                display: flex
                top: -200px

        .cluster-marker-content
            .count
                font-size: 22px
                color: #ffffff

            &.selected
                .count
                    +flex(row, center, center)

                    position: absolute
                    height: 45px
                    width: 45px
                    z-index: 10
                    left: 0
                    top: 0

                .selected-overlay
                    background-color: transparentize(#64C8EB, 0.3)

                    +flex(row, center, center)

                    border-radius: 50%
                    position: absolute
                    height: 100%
                    z-index: 100
                    flex: 1

            &.selected
                background-color: #64C8EB

                svg
                    height: 100%
                    width: 100%

        &:hover
            // border-width: 6px !important

            .marker-content, .cluster-marker-content
                background-color: #64C8EB
