.header.widget
    transition: all 0.4s ease
    background-color: #2E3043
    justify-content: center
    font-family: 'UniNeue'
    display: flex
    height: 140px
    width: 100%

    &.compact
        height: 67px

        .container
            transform: translateY(-73px)

    .container
        transition: all 0.4s ease
        height: 140px
        width: 95%
    
        @media (min-width: 768px)
            width: 95%
        @media (min-width: 1367px)
            width: 85%

    .title
        +flex(row, flex-start, center)
        letter-spacing: 0
        font-size: 44px
        height: 78px
        color: #FFF

    .actions
        +flex(row, space-between, flex-end)
        padding: 0px 30px 0px 0px
        margin-top: 12px
        height: 50px
    
        .menu
            +flex(row, flex-start, flex-start)
            
            .item
                display: inline-block
                color: #fff
                transition: all 0.25s ease
                text-decoration: none
                margin-right: 17px
                padding-left: 8px
                padding-right: 8px
                padding-bottom: 16px
                letter-spacing: 0
                font-weight: 500
                cursor: pointer
                box-sizing: border-box
                border-bottom: 4px solid transparent
    
                &.active
                    border-bottom: 4px solid #FFF
                    color: #FFF
                
                &:hover
                    color: #FFF
                    border-bottom-color: #fff
        
        .action
            +flex(row, center, center)
            background-color: #64C8EB
            transition: all 0.2s ease
            text-transform: uppercase
            letter-spacing: 1.1px
            border-radius: 4px
            min-width: 170px
            font-weight: 500
            font-size: 14px
            cursor: pointer
            height: 40px
            color: #FFF

            margin-bottom: 13px

            &:hover
                background-color: transparentize(#64C8EB, 0.2)