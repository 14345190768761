.drivers.page
    grid-template-areas: "headerMenu headerMenu" "listMenu formsContent"
    grid-template-columns: 330px 1fr
    grid-template-rows: 140px 1fr
    display: grid

    .header.widget
        grid-area: headerMenu

    .sidebar-items-list, .drivers-list
        box-shadow: 2px 0px 7px rgba(103, 116, 150, 0.15)
        background-color: #F1F2F5
        grid-area: listMenu
        height: 100%
        z-index: 100

    .page-content
        grid-area: formsContent
        position: relative
        overflow: hidden

    .info-message
        +flex(column, center, center)
        height: 100%
        width: 100%

        .content
            +flex(column, center, center)

            margin-top: -150px

            .title
                color: rgba(0, 0, 0, 0.9)
                text-align: center
                margin-top: 20px
                font-size: 22px
            
            .action
                margin-top: 20px
