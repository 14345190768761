.placeholder
    padding-top: 93px
    text-align: center
    .note
        max-width: 419px
        margin: auto
        margin-top: 16px
        font-size: 22px
        font-weight: 500

    .actions
        max-width: 296px
        margin: auto
        margin-top: 16px
        +flex(row, center, center)
        a
            transition: 0.2s ease-out
            &:not(:last-of-type)
                margin-right: 16px
            &:hover
                transform: scale(1.1)

    /*Step by step animation*/
    *
        animation: showFromTop 0.5s ease-out
        animation-fill-mode: backwards
    @for $i from 1 through 10
        *:nth-child(#{$i})
            animation-delay: 0.2s * ($i - 1)
