.form-item.contacts
    margin: 16px 0px

    .checkbox
        margin-bottom: 10px

    .radio-button
        margin: 8px 0

        label
            flex-direction: column
            display: flex
            height: 38px

            .name
                color: rgba(0,0,0, 0.9)
                font-weight: 500
                font-size: 16px
            .phone
                color: rgba(0,0,0, 0.7)
                font-weight: 500
                margin-top: 3px
                font-size: 15px