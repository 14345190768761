.ReactModal__Overlay
    background-color: transparent !important
    z-index: 9999 !important

.image-preview
    background-color: rgba(0,0,0, 0.2)
    cursor: pointer
    display: flex
    height: 100%
    width: 100%

    .content-image
        +flex(row, center, center)
        cursor: pointer
        flex: 1

        img
            max-height: 90%
            max-width: 80%
            height: 100%
            width: 100%
            object-fit: contain

// Refactored
.image-modal
    height: 70px
    max-width: 100px
    object-fit: cover
