.transports.page .all.content .filters
    border-bottom: 1px solid rgba(0, 0, 0, 0.05)
    border-radius: 0
    display: flex
    border: none
    height: 80px
    padding: 0px

    .grouping, .active-status
        justify-content: center
        flex-direction: column
        padding: 0 16px
        display: flex
        height: 80px

        .title
            text-transform: uppercase
            color: rgba(0, 0, 0, 0.7)
            margin-bottom: 7px
            font-size: 10px

        .items
            +flex(row, flex-start, center)

            .item
                border: 1px solid #D6D8E5
                border-left: none
                cursor: pointer
                transition: all 0.25s ease

                +flex(row, flex-start, center)

                white-space: nowrap
                height: 40px

                &:first-child
                    border-left: 1px solid #D6D8E5
                    border-radius: 4px 0 0 4px

                &:last-child
                    border-radius: 0 4px 4px 0

                &:hover
                    background-color: #ECEDF5

                span
                    text-transform: uppercase
                    padding-top: 2px
                    margin-left: 6px
                    font-weight: 800
                    font-size: 14px
                    color: #6C6F88

                &.active
                    background-color: #2E3043

                    span
                        color: #ffffff

    .grouping
        border-right: 1px solid rgba(0, 0, 0, 0.05)
        width: calc(330px - 32px)

        .items .item
            flex: 1

            svg
                margin-left: 6px

    .active-status
        flex: 1

        .items .item
            padding: 0 10px

.transports.page .all.content
    height: calc(100% - 140px)
    flex-direction: column
    display: flex

    .map-zone
        display: flex
        flex: 1

        .transport-list
            background-color: #F1F2F5
            position: relative
            overflow: hidden
            width: 330px
            height: 100%

        .map-container
            background-color: rgba(0, 0, 0, 0.2)
            height: 100%
            width: 100%
            flex: 1

.transports.page .all.content .transport-list
    flex-direction: column
    display: flex

    .banner
        +flex(column, center, center)

        flex: 1

        .title
            color: rgba(0, 0, 0, 0.8)
            text-align: center
            font-size: 22px
            margin: 0 16px

    .drivers-toggle
        +flex(row, space-between, center)

        // background-color: #F1F2F5
        margin: 0 16px
        height: 60px

        .title
            color: rgba(0, 0, 0, 0.55)
            letter-spacing: 0
            font-weight: 500
            font-size: 16px

    .list-container
        overflow: hidden
        flex: 1

        &.transports
            // ####

        &.orders
            // ####

.executor.transport.item
    box-shadow: 0px 0px 4px rgba(103, 116, 150, 0.246971)
    transition: all 0.2s ease
    background-color: #ffffff
    flex-direction: column
    font-family: "UniNeue"
    border-radius: 4px
    overflow: hidden
    margin: 8px 16px
    display: flex

    &.short
        margin: 0

    &.hovered:not(.opened)
        background-color: #D6F3FD

        .header .avatar
            .front, .back
                border-color: #D6F3FD

    &.opened .header .avatar
        margin-right: 12px
        width: 40px

    .header, .subheader
        .title
            height: 34px
            min-width: 0
            flex: 1

            .top, .bottom
                text-overflow: ellipsis
                white-space: nowrap
                letter-spacing: 0
                overflow: hidden
                cursor: pointer

            .top
                color: rgba(0, 0, 0, 0.8)
                font-weight: 500
                font-size: 14px

            .bottom
                color: rgba(0, 0, 0, 0.6)
                font-size: 12px

    .header
        +flex(row, flex-start, center)

        min-height: 64px
        margin: 0 12px

        .toggle
            +flex(row, center, center)

            transition: all 0.2s ease
            cursor: pointer
            height: 30px
            width: 40px

            svg
                transition: all 0.2s ease
                transform: rotate(0deg)

            &.opened svg
                transform: rotate(180deg)

        .avatar
            position: relative
            height: 40px
            width: 65px

            +disableTextSelection

            &.short
                width: 52px

            .front, .back
                background-color: rgb(246, 246, 246)
                border: 2px solid #ffffff

                +flex(row, center, center)

                border-radius: 50%
                overflow: hidden

                img
                    max-height: 100%
                    max-width: 100%
                    height: 100%
                    width: 100%

            .front
                position: absolute
                cursor: pointer
                height: 40px
                width: 40px
                z-index: 10

                .checked
                    background-color: rgba(100, 200, 235, 0.7)

                    +flex(row, center, center)

                    position: absolute
                    height: 40px
                    width: 40px
                    left: 0
                    top: 0

            .back
                position: absolute
                height: 30px
                width: 30px
                z-index: 5
                left: 26px
                top: 10px

    .content-info
        min-height: 240px
        height: 240px

    .content-info .subheader
        +flex(row, flex-start, center)

        margin: 0 12px 10px 12px

        .avatar
            transition: all 0.2s ease

            +flex(row, center, center)

            margin-right: 12px
            height: 40px
            width: 40px

            &:not(.empty) img
                max-height: 100%
                max-width: 100%
                height: 100%
                width: 100%

            &.empty img
                max-height: 80%

            .front, .back
                background-color: rgb(246, 246, 246)
                border: 2px solid #ffffff

                +flex(row, center, center)

                border-radius: 50%
                overflow: hidden

                img
                    max-height: 100%
                    max-width: 100%
                    height: 100%
                    width: 100%

.executor.order.item
    box-shadow: 0px 0px 4px rgba(103, 116, 150, 0.246971)
    background-color: #ffffff
    flex-direction: column
    font-family: "UniNeue"
    border-radius: 4px
    overflow: hidden
    margin: 8px 16px
    display: flex

    .header
        align-items: center
        min-height: 100px
        display: flex
        height: 100px

        .toggle
            +flex(row, center, center)

            transition: all 0.2s ease
            cursor: pointer
            height: 30px
            width: 40px

            svg
                transition: all 0.2s ease
                transform: rotate(0deg)

            &.opened svg
                transform: rotate(180deg)

        .bid-info-panel
            width: calc(100% - 40px)
            height: 100px
            flex: 1

    .drivers
        border-top: 1px solid rgba(0, 0, 0, 0.05)
        flex-direction: column
        padding: 5px 0px 8px 0
        max-height: 150px
        min-height: 130px
        overflow: hidden
        margin-top: 7px
        display: flex

        .driver
            margin: 4px 12px
