.profile.docs .scans .header
    text-transform: uppercase
    letter-spacing: 1.1px
    margin-bottom: 20px
    font-weight: bold
    margin-top: 20px
    font-size: 14px
    color: #2E3043

.profile.docs .scans .certificates
    transition: all 0.35s ease
    position: relative
    margin: 10px 0
    background-color: rgba(255, 255, 255, 0.25)
    border-radius: 8px
    width: 380px
    padding: 10px

    flex-wrap: wrap
    display: flex

    .item
        +flex(row, flex-start, center)

        position: relative
        height: auto
        width: auto

        .action.remove
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3)
            background-color: #fff
            border-radius: 50%
            position: absolute
            right: 8px
            top: 8px
            min-width: auto
            height: 24px
            width: 24px

            svg
                height: 14px
                width: auto

        img
            border-radius: 5px
            height: 70px
            margin: 5px
            width: auto

.profile.docs .scans .picker
    transition: all 0.35s ease
    position: relative
    margin: 10px 0
    background-color: #fff
    border-radius: 8px
    min-height: 130px
    width: 400px

    & > svg
        position: absolute

    &:hover > svg path
        stroke: #64C8EB

    .drop-container
        cursor: pointer
        display: flex
        height: 130px

        &:hover
            transition: all 0.35s ease-in

            .content
                .label svg
                    height: 0
                    width: 0

                .action
                    opacity: 1

        .content
            +flex(column, flex-start, center)

            padding: 8px 8px
            overflow: hidden
            flex: 1

            .label
                transition: all 0.35s ease-in
                text-align: center
                font-weight: 500
                margin-top: 0px
                font-size: 15px
                color: #6C6F88
                width: 100%

                +flex(column, center, center)

                svg
                    transition: all 0.25s ease-in
                    transition-delay: 0.25s
                    margin-top: 15px

            .action
                transition: all 0.35s ease-in
                transition-delay: 0.25s
                margin-top: 13px
                min-height: 40px
                opacity: 0

            .items
                margin-top: 0px !important
                flex-wrap: wrap
                display: flex

                .preview.uploading
                    +flex(row, flex-start, center)

                    position: relative
                    margin: 10px 5px

                    .overlay
                        +flex(row, center, center)

                        position: absolute
                        height: 100%
                        width: 100%

                    img
                        border-radius: 5px
                        height: 70px
                        width: auto

            &.rejected, &.accepted
                +flex(column, center, center)

                .title
                    margin-top: 15px
                    font-weight: 700
                    font-size: 16px

            &.accepted .title
                color: #64C8EB

            &.rejected .title
                color: #ebd407
