.file-picker
    transition: all 0.35s ease
    position: relative
    margin: 10px 0

    &.file-picker--invalid 
        svg path 
            fill: #FDF7C1 !important
        .input__message
            opacity: 0.7

    & > svg
        position: absolute

    &:hover > svg path
        stroke: #64C8EB

    .drop-container
        cursor: pointer
        display: flex
        height: 115px

        &:hover
            .content
                .action
                    opacity: 1

        .content
            +flex(column, flex-start, center)
            padding: 8px 8px
            flex: 1

            .label
                font-weight: 500
                font-size: 15px
                color: #6C6F88
                width: 100%
            
            .action
                margin-top: 24px
                min-height: 40px
                opacity: 0
            
            .preview
                +flex(row, flex-start, center)
                margin-top: 10px
                height: 70px
                width: 100%

                img
                    border-radius: 5px
                    height: 70px
                    width: auto
            
            &.rejected, &.accepted
                +flex(column, center, center)

                .title
                    margin-top: 15px
                    font-weight: 700
                    font-size: 16px
            
            &.accepted .title
                color: #64C8EB
            &.rejected .title
                color: #ebd407