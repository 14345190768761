.filters
    border: 1px solid rgba(0,0,0, 0.2)
    border-radius: 0 0 4px 4px
    justify-content: center
    align-items: center
    display: flex

    .container
        align-items: flex-start
        justify-content: space-between
        min-height: 70px
        margin: 10px 0
        display: flex
        width: 95%

        @media (min-width: 768px)
            width: 95%
        @media (min-width: 1367px)
            width: 85%

        .clear.icon
            border: 1px solid rgba(0,0,0, 0.15)

            border-radius: 6px
            cursor: pointer
            padding: 0 10px
            height: 46px
            display: flex
            align-items: center

            img
                transition: all 0.35s ease
                opacity: 0.6
                height: 30px
                width: auto

            .text
                transition: all 0.35s ease
                color: rgba(0,0,0,0.66)
                margin-left: 7px
                font-weight: 500
                font-size: 15px

            &:hover img
                opacity: 1
            &:hover .text
                color: #000

    .builder
        // align-items: flex-end
        // flex-direction: row
        // display: flex
        // flex-wrap: wrap
        // flex: 1
        display: grid
        grid-template-columns: 200px 200px 200px 200px
        grid-gap: 10px

        .multi-label
            position: absolute
            z-index: 9999
            font-size: 10px
            padding-left: 10px
            padding-top: 2px
        .multi-select
            min-width: 190px
            height: 50px



        .group.distances, .group.prices, .group.prices-km, .group.weights
            align-items: flex-end
            position: relative
            margin: 0 10px
            width: 140px
            height: 70px

            .header
                color: rgba(0,0,0, 0.66)
                text-align: center
                position: absolute
                font-weight: 500
                font-size: 15px
                width: 100%
                top: 0

            .input
                border-radius: 5px 0px 0px 5px
                height: 40px
                width: 70px

                &:last-child
                    border-radius: 0px 5px 5px 0px
                    border-left: none
