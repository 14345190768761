.example {
	position: absolute;
	top: 0;
	left: 0px;
}
/* .example-enter {
    opacity: 0;
    right: 300px;
} */
.example-enter {
	opacity: 0;

	transform: translateX(100%);
}
.example-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition:
		transform 500ms,
		opacity 500ms;
}
.example-enter-done {
	opacity: 1;
	right: 0px;
	transform: translateX(100%);
}
.example-exit {
	opacity: 1;
	transform: translateX(0);
}
.example-exit-active {
	opacity: 0;
	transform: translateX(100%);
	transition:
		transform 500ms,
		opacity 500ms;
}

.example-exit-done {
	/* right: ; */
	opacity: 0;
	right: -300px;
}
