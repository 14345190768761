=button($bg, $color, $height, $width: 100%)
    +flex(row, center, center)

    transition: all 0.2s ease
    text-transform: uppercase
    background-color: $bg
    letter-spacing: 1.4
    border-radius: 4px
    position: relative
    font-weight: 500
    cursor: pointer
    font-size: 11px
    height: $height
    width: $width
    border: none
    color: #FFF

    &:active + &:hover
        outline: none

    &:hover
        background-color: darken($bg, 15%)

.login.page.stores
    font-family: "UniNeue"

    .info
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)

        +flex(column, center, center)

        background-color: #ffffff
        border-radius: 6px
        padding: 16px
        height: 90%
        width: 90%

        .title
            color: rgba(0, 0, 0, 0.8)
            text-align: center
            font-weight: 500
            font-size: 24px

        .description
            margin-bottom: 40px
            margin-top: 10px
            color: rgba(0, 0, 0, 0.66)
            text-align: center
            font-size: 15px

        .markets
            .link
                border: 1px solid rgba(0, 0, 0, 0.05)

                +flex(row, center, center)

                background-color: #F5F5F5
                text-decoration: none
                border-radius: 6px
                cursor: pointer
                height: 50px
                width: 200px

                .text
                    color: rgba(0, 0, 0, 0.87)
                    margin-left: 10px
                    font-size: 18px

                img
                    height: 40px
                    width: auto

.login.page
    +flex(row, center, center)

    background-color: #E0E0E0
    height: 100vh

    .form
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
        flex-direction: column
        font-family: "UniNeue"
        border-radius: 6px
        display: flex
        width: 400px

        &.loading
            .header, .content, .error, .bottom
                filter: blur(10px)

        .loading
            background-color: rgba(255, 255, 255, 0.2)

            +flex(row, center, center)

            position: absolute
            height: 100%
            z-index: 100
            width: 100%
            right: 0
            left: 0
            top: 0

        &.role
            flex-direction: column
            display: flex

            .header
                background-color: #FFF

                h1
                    color: rgba(0, 0, 0, 0.87)
                    text-align: center

            .options
                +flex(row, space-between, center)

                .option
                    +button(transparent, #fff, 46px, calc(40% - 10px))

                    padding-left: 8px
                    width: 50%

                    img
                        margin-right: 5px
                        height: 60%
                        width: auto

                    .title
                        margin-bottom: -3px
                        padding-left: 10px
                        text-align: left
                        font-size: 14px
                        flex: 1

                    &:first-child
                        background-color: #5C6BC0
                        border-radius: 0 0 0 6px

                        &:hover
                            background-color: darken(#5C6BC0, 15%)

                    &:last-child
                        background-color: #66BB6A
                        border-radius: 0 0 6px 0

                        &:hover
                            background-color: darken(#66BB6A, 15%)

        .header
            border-radius: 6px 6px 0 0
            background-color: #FFF
            padding: 16px

            h1
                color: rgba(0, 0, 0, 0.9)
                letter-spacing: 0
                font-weight: 500
                font-size: 18px
                padding: 0
                margin: 0

        .content
            +flex(row, space-between, center)

            padding: 16px 16px 10px 16px
            background-color: #FFF

            .input.rounded
                width: 60%

            .submit
                +button(#64c8eb, #fff, 46px, calc(40% - 10px))

        .error
            +flex(row, center, center)

            padding: 0 16px

            .message
                letter-spacing: 1
                font-weight: 500
                font-size: 15px
                color: #FFF

        .bottom
            padding: 5px 16px 16px 16px
            border-radius: 0 0 6px 6px
            color: rgba(0, 0, 0, 0.7)
            background-color: #FFF
            font-size: 11px

            .title
                strong
                    margin-left: 5px

            .actions
                +flex(row, flex-start, center)

                margin-top: 3px

                .action
                    border-bottom: 1px solid rgba(0, 0, 0, 0.7)
                    background-color: transparent
                    color: rgba(0,0,0, 0.65)
                    text-transform: none
                    margin: 0 12px 0 0
                    letter-spacing: 0
                    border-radius: 0
                    font-weight: 400
                    min-width: auto
                    font-size: 12px
                    cursor: pointer
                    height: auto
                    width: auto
                    padding: 0
