.info-label
    & > .title
        color: rgba(0,0,0, 0.7)
        font-weight: 500
        font-size: 11px
        margin-bottom: 3px

    & > .text
        font-weight: 500
        font-size: 16px
        color: #000000
