.menu
    +flex(row, flex-start, flex-start)
    .item
        display: inline-block
        color: #fff
        transition: all 0.25s ease
        text-decoration: none
        margin-right: 17px
        padding-left: 8px
        padding-right: 8px
        padding-bottom: 16px
        letter-spacing: 0
        font-weight: 500
        cursor: pointer
        box-sizing: border-box
        border-bottom: 4px solid transparent

        &.active
            border-bottom: 4px solid #FFF
            color: #FFF
        &:hover
            color: #FFF
            border-bottom-color: #fff
