.preloader.page
    +flex(column, center, center)

    font-family: "UniNeue"
    height: 100%
    width: 100%

    img
        width: 200px
        height: auto

    h4
        color: rgba(0, 0, 0, 0.76)
        margin-top: 30px
        font-weight: 500
        font-size: 22px
