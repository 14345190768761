.builder
    &__comment
        position: relative
        background: #64c8eb
        border-radius: 6px
        box-shadow: 0 4px 4px rgba(0,0,0,0.2)
        color: white
        padding: 16px
        h4
            font-size: 16px
            letter-spacing: 0.2px
            margin-bottom: 10px
        p
            margin: 0
    .form-item.range, .form-item.slider
        flex-direction: column
        align-items: center
        display: flex
        min-width: 150px

        .rc-slider
            width: 140px
            height: 16px

            .rc-slider-handle
                background-color: transparent
                margin-left: -7px
                margin-top: -7px
                width: 14px
                height: 14px

        .values
            +flex(row, space-between, center)

            margin-top: 5px
            height: 14px
            width: 100%

            .min, .max
                color: rgba(0, 0, 0, 0.6)
                font-size: 11px

            .min
                margin-left: 2px

            .max
                margin-right: 2px

    .group
        display: flex

        &.hidden
            display: none

        &.row
            flex-direction: row

        &.column
            flex-direction: column
