$switch-width: 40px // Change Me
$switch-height: calc($switch-width / 2.5)

label.switch
    height: $switch-height * 1.4
    width: $switch-width
    position: relative

    input[type="checkbox"]
        display: none
        
        &:checked ~ .toggle
            left: ($switch-width - calc(($switch-height * 1.4 - $switch-height) / 2)) - $switch-height
            background: #64C8EB
            transition: .5s
        
        &:checked ~ .switch
            background: rgba(100,200,235, 0.5)
            transition: .5s
    .switch
        display: block
        width: $switch-width
        height: $switch-height
        background: #939393
        border-radius: calc($switch-height / 2)
        position: absolute
        top: calc((($switch-height * 1.4) - $switch-height) / 2)
        transition: .5s
        cursor: grab

    .toggle
        cursor: grab
        height: $switch-height * 1.4
        width: $switch-height * 1.4
        border-radius: 50%
        background: white
        position: absolute
        // top: ($switch-height * 1.4 - $switch-height) / -2
        left: calc(($switch-height * 1.4 - $switch-height) / -2)
        box-shadow: 0 calc($switch-width / 50) calc($switch-width / 25) rgba(black, .4)
        transition: .5s
        z-index: 10

    .card
        display: inline-block
        margin: calc(($switch-height * 1.4 - $switch-height) / 2) + 5px
        margin: calc(($switch-height * 1.5 - $switch-height) / 2)
        width: $switch-width
        height: $switch-height
        text-align: center
        position: relative

// Second switch coloration
// #switch2
//   &:checked ~ .toggle
//     background: #3F51B5

//   &:checked ~ .switch
//     background: #9CA5D7  
