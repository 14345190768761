.drivers.page .sidebar-items-list, .drivers.page .drivers-list
    flex-direction: column
    display: flex

    .action.add
        +flex(row, flex-start, center)

        background-color: #E6E8ED
        transition: all 0.35s ease
        border-radius: 5px
        font-weight: 700
        font-size: 14px
        padding: 0 12px
        cursor: pointer
        color: #6C6F88
        height: 64px
        margin: 16px

        +disableTextSelection

        &:hover
            background-color: darken(#E6E8ED, 10%)

        &.team
            background-color: #E6E8ED
            text-transform: none
            font-weight: 500
            font-size: 14px
            color: #6C6F88

            .plus
                background-color: #ffffff
                color: #E6E8ED

        .plus
            +flex(row, center, center)

            background-color: #64C8EB
            border-radius: 50%
            margin-right: 10px
            font-weight: 300
            font-size: 35px
            color: #ffffff
            height: 40px
            min-width: 40px

            span
                margin-right: -3px
                margin-top: -2px

        .disabled
            pointer-events: none

    .header
        margin: 16px 16px 0px 16px
        text-transform: uppercase
        font-weight: 700
        font-size: 12px
        color: #6C6F88

    .car.item, .driver.item
        +flex(row, flex-start, center)

        transition: all 0.35s ease
        background-color: #FFF
        border-radius: 5px
        cursor: pointer
        margin: 8px 16px
        padding: 0 12px
        height: 64px

        & > .info
            +flex(row, flex-start, center)
            width: 100%

        &:hover, &.opened
            background-color: #D6F3FD

            .avatar
                .front, .back
                    border-color: #D6F3FD

        .avatar
            position: relative
            height: 40px
            width: 65px

            +disableTextSelection

            .front, .back
                background-color: rgb(246, 246, 246)
                border: 2px solid #ffffff

                +flex(row, center, center)

                border-radius: 50%
                overflow: hidden

                img
                    max-height: 100%
                    max-width: 100%
                    height: 100%
                    width: 100%

            .front
                position: absolute
                height: 40px
                width: 40px
                z-index: 10

            .back
                position: absolute
                height: 30px
                width: 30px
                z-index: 5
                left: 26px
                top: 10px

        .title
            min-width: 0
            flex: 1

            .top, .bottom
                text-overflow: ellipsis
                white-space: nowrap
                letter-spacing: 0
                overflow: hidden
                cursor: pointer

            .top
                color: rgba(0, 0, 0, 0.8)
                font-weight: 500
                font-size: 14px

            .bottom
                color: rgba(0, 0, 0, 0.6)
                font-size: 12px
                margin-top: 5px
