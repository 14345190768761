.orders.page
    flex-direction: column
    font-family: "UniNeue"
    display: flex

    .header
        position: relative
        width: auto

    .filter-button
        background-color: #565867
        color: white
        border-radius: 4px
        padding: 10px 5px
        cursor: pointer
        height: 20px
        margin-right: 10px
        display: flex
        align-items: center
        margin-bottom: 13px
        cursor: pointer
        &:hover
            background-color: #515361
    .actions
        .filter-icon-container
            display: flex
            justify-content: space-between
            align-item: center
    .filter-action
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)

        +flex(row, center, center)

        transition: all 0.35s ease
        background-color: #03a9f4
        border-radius: 50%
        cursor: pointer
        height: 50px
        width: 50px
        top: calc(100% - 30px)
        position: absolute
        left: 2%

        &:hover
            background-color: darken(#03a9f4, 10%)

        img
            height: 25px
            width: auto

    .not-found-by-params, .loading-overlay
        +flex(row, center, center)

        flex: 1

        .title
            color: rgba(0, 0, 0, 0.7)
            margin-top: -22px
            font-weight: 500
            font-size: 22px

    .loading-overlay
        background-color: rgba(255, 255, 255, 0.8)
        position: absolute
        height: 100%
        width: 100%
        z-index: 10
        top: 0

    .all, .my, .archive
        justify-content: center
        position: relative
        display: flex
        flex: 1

        .container
            flex-direction: column
            display: flex
            padding-top: 16px
            height: 100%
            width: 95%

            @media (min-width: 768px)
                width: 95%

            @media (min-width: 1367px)
                width: 85%
            .actions
                display: flex
                border: 5px solid red
                .actions-wrapper
                    display: flex

            .content
                width: 100%
                flex: 1
                padding-bottom: 50px
                & div
                    outline: none

            &.empty-table
                +flex(column, center, center)

                img
                    margin-top: -20%
                    width: 132px
                    height: auto

                h3
                    text-align: center
                    font-weight: 500
                    font-size: 22px
                    margin: 14px 0

                .action
                    padding: 0 18px
                    height: 38px

        .table-header, .bid.item
            overflow: hidden
            display: flex
            height: 50px
            .table-header-item
                display: flex
                justify-content: flex-start
                &.sortable
                    cursor: pointer
                .sort-icon
                    margin-left: 5px
                    opacity: 0.5
                    &.sort-icon--active
                        opacity: 1
                    &.sort-icon--up
                        path
                            transform: rotate(180deg)
                            transform-origin: 39% 50%
            .start
                min-width: 120px
                width: 120px

            .from, .to
                flex: 1

            .distance
                min-width: 100px
                width: 100px

            .cargo
                min-width: 120px
                width: 120px

            .weight
                min-width: 100px
                width: 100px

            .rate
                min-width: 100px
                width: 100px
            .views
                min-width: 100px
                width: 100px
                .content
                    display: flex
                    align-items: flex-start
                    color: #6C6F88


        .table-header
            border-bottom: 1px solid rgba(0, 0, 0, 0.1)
            align-items: center

            & div
                text-transform: uppercase
                color: rgba(0, 0, 0, 0.55)
                letter-spacing: 0
                font-weight: 500
                font-size: 11px

        .bid.item
            transition: all 0.2s ease
            cursor: pointer

            & > div
                letter-spacing: 0
                font-weight: 500
                padding-top: 8px
                font-size: 14px

            .from, .to
                padding-right: 10px
                min-width: 0
                .verified-icon
                    position: absolute
                    margin-left: -22px
                    background-color: #64C8EB
                    border-radius: 25px
                    width: 13px
                    height: 13px
                .name
                    font-weight: 800

                .address, .name
                    color: rgba(0, 0, 0, 0.87)
                    text-overflow: ellipsis
                    white-space: nowrap
                    overflow: hidden

            .distance, .cargo, .weight, .rate
                font-weight: 500
                height: 50px
                color: #000
            .views .answers .inwork
                font-weight: 500
                height: 50px
                color: #6C6F88

            &:hover
                background-color: #d1eff9
