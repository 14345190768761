.profile.page
    flex-direction: column
    display: flex
    .loading-spinner
        display: flex
        justify-content: center
        padding: 24px
    .tab-container
        flex-direction: column
        display: flex
    .profile.base.preview
        .verification-actions
            display: flex
            align-items: baseline
            .action
                margin: 0
                margin-left: 16px
            .verification-timer
                margin-left: 24px
                color: #989AAC
                font-size: 10px
    .profile.base.form, .profile.docs.form
        display: flex
        padding: 40px 2.5%
        height: 100%
        width: 95%

        @media (min-width: 768px)
            padding: 40px 2.5%
            width: 95%

        @media (min-width: 1367px)
            padding: 40px 7.5%
            width: 85%
    .profile.docs.form .photos
        align-items: flex-end

    .profile.base.form .photos
        align-items: flex-start

    .alert.panel.digital-signature
        margin-top: 20px

    .profile.docs.form, .profile.base.form
        .photos
            justify-content: flex-start
            flex-direction: column
            display: flex
            width: 198px
            .drop-zone-avatar
                margin-top: 0px
                padding: 0px
            .drop-zone-container
                border: none
            .drop-zone-thumb
                border: none
                margin-left: 14px
                margin-top: 8px
            .drop-zone-title
                z-index: 9999999    
            svg
                margin-right: 25px

.profile.base.form, .profile.docs.form
    display: flex

    .common
        flex-direction: column
        display: flex
        flex: 1

        .header
            margin-bottom: 20px

            .title
                font-weight: 300
                font-size: 32px
                color: #000000

            .subtitle
                max-width: 350px
                font-weight: 400
                font-size: 20px
                margin-top: 10px
                color: #989AAC

            .action
                background-color: #2E3043
                text-decoration: none
                margin-top: 25px
                max-width: 170px

        .builder.fields
            max-width: 400px

            .form-item
                &.input, &.select, &.date
                    margin: 10px 0

                &.file-picker
                    viewBox: 0 0 400 115

            .header
                text-transform: uppercase
                letter-spacing: 1.1px
                margin-bottom: 20px
                font-weight: bold
                margin-top: 20px
                font-size: 14px
                color: #2E3043

        .need-digital-signature
            background-color: rgba(255,255,255, 0.6)
            flex-direction: column
            border-radius: 5px
            margin-top: 15px
            display: flex
            width: 400px

            .checkbox, .action
                margin: 20px 16px

            .action
                background-color: #2E3043
                text-decoration: none
                max-width: 170px
                margin-top: 0px

        .actions
            +flex(row, flex-start, center)

            margin-top: 25px

            .action
                padding: 0 16px
                width: auto

                &.loading
                    background-color: #E0E0E0
                    color: rgba(0,0,0, 0.75)

                    & > div
                        margin-right: 10px

.profile .company-info
    flex-direction: column
    overflow: hidden
    margin: 20px 0
    display: flex
    width: 400px

    .title
        font-weight: 500
        font-size: 15px
        color: #989AAC

    .type
        color: #989AAC
        font-weight: 500
        font-size: 15px

    .address
        +flex(row, flex-start, center)

        img
            height: 10px
            width: auto

        .text
            text-overflow: ellipsis
            white-space: nowrap
            overflow: hidden
            font-weight: 500
            font-size: 15px
            color: #989AAC
            min-width: 0

    .reg-codes
        +flex(row, flex-start, flex-start)

        .kpp
            margin-left: 10px

        .kpp, .ogrn
            letter-spacing: 0
            font-weight: 500
            font-size: 15px
            color: #989AAC

.profile.form.base
    .photos
        align-items: flex-start

    .email-verified
        color: rgba(0, 0, 0, 0.7)
        margin-bottom: -5px
        font-weight: 500
        margin-top: 10px
        font-size: 14px
    .legal-type-checkbox-container
        margin-bottom: 40px 
        .legal-type-checkbox 
            margin-left: 10px
        .wrong-inn-message        
            position: absolute
            margin-left: 420px
            margin-top: -80px
            background-color: #FDF7C1 
            padding: 15px
            border-radius: 5px
            
    
    .avatar
        border: 1px solid rgba(0, 0, 0, 0.1)
        overflow: hidden
        cursor: pointer

        &, & .dropzone
            transition: all 0.2s ease
            border-radius: 50%
            height: 128px
            width: 128px

        .dropzone
            +flex(row, center, center)

        img
            height: auto
            width: 128px

        .message
            text-shadow: 0 0 4px rgba(0, 0, 0, 0.7)

            +flex(row, center, center)

            text-transform: uppercase
            transition: all 0.2s ease
            position: absolute
            letter-spacing: 0
            font-weight: 800
            font-size: 14px
            color: #ffffff
            height: 128px
            width: 128px
            top: -128px

        &:hover
            img
                filter: sepia(0.2) blur(4px)

            .message
                top: 0

.profile.docs.form
    background-color: #F1F2F5

// Refactored styles
.profile
    .columns
        & > div:first-child
            width: 200px
    &.docs
        & > div:first-child
            width: 200px
    &__verify-icon
        text-align: right
        padding-right: 25px
        box-sizing: border-box
