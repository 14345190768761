.profile.page
    .row
        display: flex

        .list
            +flex(column, flex-start, flex-start)

        .info
            margin-left: 100px

            .text
                color: rgba(0, 0, 0, 0.7)
                font-weight: 500
                font-size: 20px

    .contacts
        padding-top: 16px
        height: 100%
        width: 95%

        @media (min-width: 768px)
            width: 90%

        @media (min-width: 1300px)
            width: 85%

        .contact
            justify-content: flex-start
            flex-direction: row
            align-items: center
            font-weight: 500
            display: flex

            &:not(:last-of-type)
                margin-bottom: 16px

            .photo
                border: 1px solid rgba(0, 0, 0, 0.15)
                border-radius: 50%
                margin-right: 16px
                overflow: hidden
                height: 64px
                width: 64px

                img
                    max-height: 64px
                    height: auto
                    width: 64px

                &.default img
                    margin-top: 7px

            .data
                justify-content: space-between
                flex-direction: column
                min-height: 24px
                display: flex

                .name
                    margin-bottom: 10px

                .phone
                    margin-bottom: 10px
                    font-size: 12px
                    color: #999999

                .actions
                    flex-direction: row
                    font-size: 13px
                    display: flex

                    button
                        margin-right: 8px
                        font-weight: 500
                        color: #5FC8ED
                        padding: 0

                        &.delete
                            color: #eb5e59
