@keyframes slideOutLeft
  from
    transform: translate3d(0, 0, 0)

  to
    transform: translate3d(-100%, 0, 0)
    visibility: hidden
    padding: 0px
    height: 0px

@keyframes slideInDown
  from
    transform: translate3d(0, -100%, 0)

  to
    transform: translate3d(0, 0, 0)

@keyframes slideOutUp
  from
    transform: translate3d(0, 0, 0)

  to
    transform: translate3d(0, -100%, 0)


@keyframes showFromTop
    from
        opacity: 0
        transform: translate3d(0, -30px, 0)
    to
        opacity: 1
        transform: none
