@import url(https://fonts.googleapis.com/css?family=Roboto);

$radio-button-checked-color: #64c8eb;
$radio-button-border-color: rgba(0, 0, 0, 0.4);
$radio-button-size: 20px;
$radio-button-checked-size: 12px;
$radio-button-ripple-size: 15px;

@keyframes ripple {
	0% {
		box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
	}
	50% {
		box-shadow: 0px 0px 0px $radio-button-ripple-size rgba(0, 0, 0, 0.1);
	}
	100% {
		box-shadow: 0px 0px 0px $radio-button-ripple-size rgba(0, 0, 0, 0);
	}
}

.radio-button {
	margin: 16px 0;
	font-size: 16px;

	&.radio-button-inline {
		display: inline-block;
	}

	input[type='radio'] {
		display: none;
		&:checked + label:before {
			border-color: $radio-button-checked-color;
			animation: ripple 0.2s linear forwards;
		}
		&:checked + label:after {
			transform: scale(1);
		}
	}

	label {
		display: inline-block;
		height: $radio-button-size;
		position: relative;
		padding: 0 ($radio-button-size + 10px);
		margin-bottom: 0;
		cursor: pointer;
		vertical-align: bottom;
		font-size: 16px;
		font-weight: 500;
		&:before,
		&:after {
			position: absolute;
			content: '';
			border-radius: 50%;
			transition: all 0.3s ease;
			transition-property: transform, border-color;
		}
		&:before {
			left: 0;
			top: 0;
			width: $radio-button-size;
			height: $radio-button-size;
			border: 2px solid $radio-button-border-color;
		}
		&:after {
			// top: $radio-button-size / 2 - $radio-button-checked-size / 2;
			// left: $radio-button-size / 2 - $radio-button-checked-size / 2;

			// Hardcode fix
			left: 6px;
			top: 6px;

			width: $radio-button-checked-size;
			height: $radio-button-checked-size;
			transform: scale(0);
			background: $radio-button-checked-color;
		}
	}
}
