.drivers.page .page-content
    .base.form, .docs.form
        padding: 40px 60px
        display: flex

        .action.loading
            background-color: #E0E0E0
            color: rgba(0,0,0, 0.75)
            
            & > div
                margin-right: 10px

        .photos
            margin-right: 35px
            width: 93px

        .common
            flex-direction: column
            display: flex
            flex: 1

            .header
                margin-bottom: 20px

                .title
                    font-weight: 300
                    font-size: 32px
                    color: #000000

                .subtitle
                    max-width: 450px
                    margin-top: 15px
                    font-weight: 400
                    font-size: 20px
                    color: #989AAC

                .action
                    background-color: #2E3043
                    margin-top: 25px
                    max-width: 170px

            .builder.fields
                max-width: 400px

                .form-item
                    &.input, &.select
                        margin: 10px 0

                .header
                    text-transform: uppercase
                    letter-spacing: 1.1px
                    margin-bottom: 20px
                    font-weight: bold
                    margin-top: 20px
                    font-size: 14px
                    color: #2E3043

            .actions
                +flex(row, flex-start, center)

                margin-top: 25px

                .action
                    padding: 0 16px
                    width: auto
                .cancel-button
                    margin-left: 20px    

.drivers.page .page-content .opened-car .base.form
    
    .photos
        +flex(column, center, center)

        .car-photo
            border: 1px solid rgba(0, 0, 0, 0.1)
            overflow: hidden
            cursor: pointer
            .drop-zone-avatar
                margin-top: 0px
                padding: 0px
            .drop-zone-container
                border: none
            .drop-zone-thumb
                border: none
                height: 90px
                width: 110px
            .drop-zone-thumbs
                margin-top: -10px
                margin-left: -5px                   
            .drop-zone-title
                z-index: 9999999 
                position: relativel
            &, & .dropzone, & img
                transition: all 0.2s ease
                border-radius: 50%
                height: 82px
                width: 82px

            .message
                text-shadow: 0 0 4px rgba(0, 0, 0, 0.5)

                +flex(row, center, center)

                text-transform: uppercase
                transition: all 0.2s ease
                position: absolute
                letter-spacing: 0
                font-weight: 800
                font-size: 13px
                color: #ffffff
                height: 82px
                width: 82px
                display: none
                opacity: 0

            &:hover
                img                
                    filter: sepia(0.2) blur(4px)

                .message
                    display: block
                    opacity: 1
                    margin-top: 32px
                    margin-left: 6px

        .arrow
            margin: 20px 0

        .driver
            +flex(column, center, center)

            .avatar
                border: 1px solid rgba(0, 0, 0, 0.1)

                +flex(row, center, center)

                border-radius: 50%
                overflow: hidden
                height: 82px
                width: 82px

                img
                    height: 82px
                    width: 82px

                &.empty img
                    height: 50px
                    width: auto

            .fullname
                color: rgba(0, 0, 0, 0.9)
                text-align: center
                font-weight: 500
                margin-top: 10px
                font-size: 16px

.drivers.page .page-content .profile.base.form
    .photos
        +flex(row, center, flex-start)

.drivers.page .page-content .docs.form
    background-color: #F1F2F5
    overflow: hidden
    height: auto

    .alert.panel.verified
        background-color: #fff
        width: 650px

        .title
            font-size: 16px !important
            color: #6C6F88 !important
            font-weight: 500
        .icon
            border: none
            height: 50px
            width: 50px

    .photos
        +flex(row, center, flex-start)

        padding-top: 50px

    .certificates.forms.list, .insurances.forms.list
        flex-direction: column
        overflow: visible
        max-width: 400px
        margin-top: 30px
        display: flex
        height: auto
        flex: none

        .action.new
            border: 1px solid rgba(0, 0, 0, 0.1)
            background-color: transparent
            color: rgba(0, 0, 0, 0.8)
            max-width: none

        & > .header
            color: rgba(0, 0, 0, 0.78)
            font-weight: 500
            margin-top: 30px
            font-size: 18px

        .certificate.item, .insurance.item
            background-color: transparent
            flex-direction: column
            margin: 10px 0
            display: flex

            .form
                .action
                    max-width: 180px
                    &.loading
                        background-color: lighten(#2E3043, 10%)
                        color: rgba(255,255,255, 0.7)

                        & > div
                            margin-right: 10px

                .form-item.date.rounded
                    margin: 10px 0

            .preview
                flex-direction: column
                display: flex

                .row
                    display: flex

                    .column
                        flex: 1

                .field
                    margin: 30px 0

                    .title
                        font-weight: 500
                        font-size: 15px
                        color: #6C6F88
                        margin: 0

                    .text
                        margin-top: 4px
                        font-weight: 500
                        font-size: 16px
                        color: #1C1C1C

                    &.photo
                        .title .sub
                            color: #6C6F88
                            margin-top: 4px
                            font-size: 15px
                            display: block

                        .image
                            +flex(row, flex-start, center)

                            margin-top: 8px
                            height: 90px
                            width: auto

                            img
                                border-radius: 5px
                                max-width: 100%
                                height: 80px
                                width: auto

            .titlebar
                +flex(row, flex-start, center)

                min-height: 64px
                height: 64px

                .type
                    border-right: 1px solid rgba(0, 0, 0, 0.15)
                    color: rgba(0, 0, 0, 0.87)
                    margin-left: 14px
                    font-weight: 500
                    padding: 10px 0
                    font-size: 15px
                    width: 90px

                .actions
                    +flex(row, flex-end, center)

                    margin-right: 12px
                    margin-top: 0
                    flex: 1

                    .draft
                        color: rgba(0, 0, 0, 0.6)
                        font-weight: 500
                        font-size: 12px

                    .edit
                        border: 1px solid rgba(0, 0, 0, 0.05)

                        +flex(row, center, center)

                        border-radius: 5px
                        font-weight: 500
                        font-size: 15px
                        margin: 0 3px
                        height: 30px
                        width: 150px

                        svg
                            margin-right: 7px
                            height: 16px
                            width: 16px

                .titles
                    +flex(column, center, flex-start)

                    .header
                        color: rgba(0, 0, 0, 0.78)
                        font-weight: 500
                        font-size: 18px
                        margin: 0

                    .subheader
                        color: rgba(0, 0, 0, 0.66)
                        font-weight: 400
                        margin-top: 6px
                        font-size: 15px

        .insurance.item
            .actions
                .edit
                    width: 30px !important
                    svg
                        margin: 0 !important