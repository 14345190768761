// JivoSite
jdiv
    font-family: "UniNeue" !important

    textarea.custom, input.custom, .inputField_G5.__bluredEmpty_1o.custom
        box-shadow: none !important

        &:focus, &:hover
            border-color: #64c8eb !important

.label_39._left_2d._pad100_GR._init_Tk.notranslate.custom
    border-radius: 0 0 6px 6px !important
    bottom: 70px !important

.headerBox_3s.custom
    border-radius: 6px 6px 0 0 !important

.leaf_25._left_MI
    display: none !important

jdiv.copy_2W
    display: none

.chatCopyright_3s > jdiv:nth-child(1), .leaf_25._bottom_DW
    display: none !important

.chatCopyright_3s.__chat_35
    display: none !important

jdiv.main_-F.__logo_3P
    padding-top: 15px !important

.headerBox_3s
    background: none !important
    background-color: #2e3043 !important
    border-radius: 6x 6px 0 0 !important

.button_Jn
    background-color: #64c8eb !important
    transition: all 0.3s ease !important

    &:hover
        background-color: #64c8eb !important
