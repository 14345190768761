.input.address
  font-family: 'UniNeue'

  .input.rounded
    &.opened
      // border-radius: 6px 6px 0 0
      // border-bottom: none
  
  .menu
    border: 1px solid rgba(0,0,0, 0.2)
    // border-radius: 0 0 6px 6px
    background-color: #ffffff
    border-radius: 6px
    position: absolute
    z-index: 9999

    margin-top: 3px

    flex-direction: column
    width: calc(100% - 2px)
    min-height: 125px
    display: flex
  
    .empty
      +flex(row, center, center)
      color: rgba(0,0,0, 0.7)
      letter-spacing: 0
      font-weight: 500
      font-size: 16px
      height: 50px
      width: 100%

    .secondary
      color: rgba(0,0,0, 0.45)
      flex-grow: 1
      flex-shrink: 1
      
    .primary
      color: rgba(0,0,0, 0.75)      
      flex-grow: 0
      flex-shrink: 0 

    .item
      transition: all 0.2s ease      
      text-overflow: ellipsis
      white-space: nowrap
      overflow: hidden
      font-weight: 500
      padding: 4px 5px
      cursor: pointer
      font-size: 15px
      margin-right: 0            

    .item-container
      display: flex
      width: 100%

      &.selected
        background-color: rgba(0,0,0, 0.1)