.sidebar.right
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)
    transition: all 0.35s ease
    background-color: #fff
    font-family: 'UniNeue'
    overflow: hidden
    z-index: 1000

    flex-direction: column
    display: flex

    &.opened
        width: 300px
    &.closed
        width: 0px

    > .header
        border-bottom: 1px solid rgba(0,0,0, 0.15)
        +flex(row, space-between, center)
        width: 300px
        height: 54px

        .title
            margin-left: 16px
            letter-spacing: 0
            font-weight: 500
            font-size: 22px
            color: #1B1B1B
            flex: 1

        .icon
            +flex(row, center, center)
            transition: all 0.2s ease
            margin-right: 0px
            cursor: pointer
            height: 40px
            width: 40px

            img
                transition: all 0.2s ease
                opacity: 0.6

            &.clear
                border: 1px solid rgba(0,0,0, 0.15)
                border-radius: 5px

                img, svg
                    height: 30px
                    width: auto

            &:hover img
                opacity: 1

        .edit
            border: 1px solid rgba(0,0,0, 0.15)
            +flex(row, center, center)
            transition: all 0.2s ease
            border-radius: 5px
            cursor: pointer

            height: 36px
            width: 36px

            svg
                opacity: 0.4
                height: 20px
                width: auto

            &:hover svg
                opacity: 0.8
