.sidebar.details    
    .info
        flex-direction: column
        min-width: 18em
        display: flex

        .not-found
            +flex(column, center, center)
            margin-top: 40px

            svg
                width: 150px
                height: auto

            .title
                color: rgba(0,0,0, 0.8)
                margin-top: 15px
                font-weight: 500
                font-size: 22px

        .from, .to
            flex-direction: column
            padding: 10px 16px
            display: flex

            .title
                +flex(row, flex-start, center)

                text-transform: uppercase
                letter-spacing: 0
                font-weight: 800
                font-size: 10px
                color: #64c8eb

                img
                    margin-right: 8px
                    height: 10px
                    width: auto

            .name
                color: rgba(0, 0, 0, 0.85)
                letter-spacing: 0
                font-weight: 700
                font-size: 16px
                margin-top: 5px

            .address
                margin-top: 2px
                color: rgba(0, 0, 0, 0.7)
                letter-spacing: 0
                font-weight: 500
                font-size: 15px

        .field
            border-bottom: 1px solid rgba(0, 0, 0, 0.15)
            padding: 12px 16px

            .title
                text-transform: uppercase
                letter-spacing: 0
                font-weight: 800
                font-size: 10px
                color: #64c8eb

            .text
                color: rgba(0, 0, 0, 0.85)
                letter-spacing: 0
                font-weight: 500
                font-size: 16px
                margin-top: 5px

            .addittional
                color: rgba(0, 0, 0, 0.6)
                letter-spacing: 0
                font-weight: 500
                font-size: 12px
                margin-top: 3px

            &.contact
                .text
                    +flex(column, flex-start, flex-start)

                    .phone
                        color: rgba(0, 0, 0, 0.67)
                        margin-top: 6px
                        font-size: 14px

            &.delivery
                .text
                    +flex(row, flex-start, center)

                    .divider
                        color: rgba(0, 0, 0, 0.6)
                        font-size: 18px
                        margin: 0 4px
