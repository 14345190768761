.faq
    flex-direction: column
    display: flex

    &__container
        padding: 25px 40px
        max-width: 900px
        box-sizing: border-box
        margin: auto

    .header
        border-bottom: 1px solid rgba(0, 0, 0, 0.05)
        color: rgba(0, 0, 0, 0.8)
        padding-bottom: 15px
        margin-bottom: 15px
        font-weight: 500
        font-size: 24px
        text-align: center

    .items
        flex-direction: column
        display: flex

        .item
            margin: 10px 0
            display: flex

            .icon
                border: 1px solid rgba(0, 0, 0, 0.1)
                border-radius: 50%
                height: 50px
                width: 50px
                display: flex
                align-items: center
                justify-content: center

            .description
                flex-direction: column
                display: flex
                margin-left: 15px
                flex: 1

                .title
                    color: rgba(0, 0, 0, 0.66)
                    margin-bottom: 10px
                    font-weight: 500
                    font-size: 18px

                .info
                    color: rgba(0, 0, 0, 0.7)
                    letter-spacing: 0
                    font-weight: 400

            .sub-info
                border-left: 4px solid rgba(0, 0, 0, 0.1)
                flex-direction: column
                margin-top: 15px
                padding: 10px 0
                display: flex

                .title, .info
                    margin-left: 30px
