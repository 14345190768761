.transport-actions
    border-bottom: 1px solid rgba(0, 0, 0, 0.05)
    border-top: 1px solid rgba(0, 0, 0, 0.05)
    padding: 0 5px
    display: flex

    .item
        +flex(column, center, center)

        transition: all 0.2s ease
        cursor: pointer
        height: 60px
        flex: 1

        &.disabled
            opacity: 0.4

        &.checked
            .title
                color: #64C8EB

        .icon
            +flex(row, center, center)

            height: 32px

        .title
            text-transform: uppercase
            letter-spacing: 0
            margin-top: 5px
            font-size: 10px
            color: #6C6F88

.bid-info-panel
    flex-direction: column
    display: flex

    .basic
        padding: 7px 5px 7px 5px
        align-items: center
        display: flex

        .item
            background-image: linear-gradient(#BFBFBF 33%, rgba(255, 255, 255, 0) 0%)
            background-repeat: repeat-y
            background-position: right
            background-size: 1px 6px

            +flex(column, center, center)

            transition: all 0.2s ease
            cursor: pointer
            padding: 6px 0
            flex: 1

            .title, .subtitle
                color: rgba(0, 0, 0, 0.8)
                font-weight: 700

            .title
                font-size: 16px

            .subtitle
                margin-top: 2px
                font-size: 10px
                height: 12px

            &:last-child
                background: none

    .addresses
        flex-direction: column
        display: flex

        .item
            +flex(row, flex-start, center)

            min-height: 13px
            margin: 2px 12px
            height: 15px
            min-width: 0
            flex: 1

            &:first-child
                margin-top: 0

            .icon
                +flex(row, center, center)

                height: 12px
                width: 12px

            .address
                text-overflow: ellipsis
                white-space: nowrap
                letter-spacing: 0
                overflow: hidden
                margin-left: 5px
                cursor: pointer
                font-size: 12px
                color: #6C6F88
                flex: 1

    .dates
        letter-spacing: 0
        padding: 0 12px
        margin-top: 8px
        font-size: 12px
        color: #6C6F88
        display: flex

        .divider
            margin: 0 4px

.driver-header
    +flex(row, flex-start, center)

    .avatar
        position: relative
        height: 40px
        width: 65px

        +disableTextSelection

        .front, .back
            background-color: rgb(246, 246, 246)
            border: 2px solid #ffffff

            +flex(row, center, center)

            border-radius: 50%
            overflow: hidden

            img
                max-height: 100%
                max-width: 100%
                height: 100%
                width: 100%

        .front
            position: absolute
            height: 40px
            width: 40px
            z-index: 10

        .back
            position: absolute
            height: 30px
            width: 30px
            z-index: 5
            left: 26px
            top: 10px

    .title
        min-width: 0
        flex: 1

        .top, .bottom
            text-overflow: ellipsis
            white-space: nowrap
            letter-spacing: 0
            overflow: hidden
            cursor: pointer

        .top
            color: rgba(0, 0, 0, 0.8)
            font-weight: 500
            font-size: 14px

        .bottom
            color: rgba(0, 0, 0, 0.6)
            font-size: 12px
            margin-top: 5px

.selected-panel
    // box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25)
    overflow: hidden
    z-index: 100
    width: 100%

    .selected-info
        background-color: #ffffff
        align-items: center
        min-height: 60px
        padding: 0 14px
        display: flex
        height: 60px

        .count
            align-items: center
            margin-left: 15px
            display: flex
            min-width: 0
            flex: 1

            .title
                white-space: nowrap
                letter-spacing: 0
                overflow: hidden
                cursor: pointer
                font-size: 16px
                color: #6C6F88

        .clear, .actions .item
            +flex(row, center, center)

            cursor: pointer
            height: 30px
            width: 30px

        .actions
            display: flex

            .item
                margin: 0 5px

.selected-route-options
    +flex(column, flex-start, flex-start)

    .checkbox
        margin: 5px 0

        span
            font-weight: 400
            color: #6C6F88
