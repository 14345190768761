.input
    position: relative
    input[type="file"]
        display: none

    input 
        transition: 0.25s
    &__mask
        transform: translate(0, 5px)
        justify-content: flex-start
        display: inline-flex
        pointer-events: none
        flex-direction: row
        position: absolute
        transition: 0.15s
        font-weight: 500
        opacity: 0
        left: 10px
        top: 22px
        &-hidden
            opacity: 0
            pointer-events: none

    input[type="text"]:focus + .input__mask
        transform: none
        opacity: 0.5
    &.input--invalid
        input
            background-color: #FDF7C1 !important
        .input__message
            opacity: 0.7

    &__message
        position: absolute
        font-weight: bold
        margin-left: 30px
        transition: 0.5s
        font-size: 15px
        color: #6C6F88
        width: 180px
        opacity: 0
        left: 100%
        top: 0

.input.rounded
    border: 1px solid rgba(0, 0, 0, 0.15)
    background-color: #FFF
    font-family: 'UniNeue'
    border-radius: 6px
    // overflow: hidden
    min-height: 46px
    display: flex

    textarea
        resize: none

    &.valid label
        color: rgba(0, 0, 0, 0.7)
        font-weight: 500
        font-size: 11px
        top: 3px

    input, textarea
        padding: 18px 10px 2px 10px
        background-color: #FFF
        border-radius: 6px
        font-weight: 500
        font-size: 16px
        display: block
        border: none
        color: #000
        flex: 1

        &:focus
            // box-shadow: 0 0 3px transparentize(#5FC8ED, 0.9) !important
            outline: none

        &:focus ~ label, &:valid ~ label, &:disabled ~ label, &:not(:placeholder-shown) ~ label
            color: rgba(0, 0, 0, 0.7)
            font-weight: 500
            font-size: 11px
            top: 3px

    input[type="number"]
        -moz-appearance: textfield

    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
        -webkit-appearance: none

    textarea
        min-height: 100px

    label
        transition: 0.2s ease all
        pointer-events: none
        font-weight: normal
        position: absolute
        font-weight: 500
        font-size: 16px
        color: #999
        left: 10px
        top: 14px

.input.material
    font-family: 'UniNeue'
    overflow: hidden
    padding-top: 4px
    max-width: 100%
    width: 100%

    input
        padding: 16px 10px 4px 5px
        background-color: #ffffff
        font-weight: 500
        font-size: 16px
        display: block
        border: none
        width: 100%
        color: #000
        -moz-appearance: none
        -webkit-appearance: none

        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button
            -webkit-appearance: none

        &:focus
            outline: none

        &:focus ~ label, &:valid ~ label
            font-weight: 500
            font-size: 12px
            color: #64C8EB
            top: 0px

    input:focus ~ .bar
         &:after
            width: 100%

    input:focus ~ .highlight
        animation: inputHighlighter 0.3s ease

    input[type="number"]
        -moz-appearance: textfield

    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
        -webkit-appearance: none

    label
        transition: 0.2s ease all
        pointer-events: none
        font-weight: normal
        position: absolute
        font-weight: 500
        font-size: 16px
        color: rgba(0, 0, 0, 0.6)
        left: 5px
        top: 16px

    .bar
        position: relative
        display: block
        width: 100%
        height: 2px
        &:before, &:after
            transition: 0.2s ease all
            background: #64C8EB
            position: absolute
            height: 2px
            content: ''
            width: 0
            bottom: 0
            left: 50%
            transform: translate(-50%, 0)
            border-bottom-right-radius: 5px
            border-bottom-left-radius: 5px
        &:before
            width: 100%
            background-color: #D6D8E5
        &:after
            background-color: #64C8EB

    .highlight
        pointer-events: none
        position: absolute
        width: 100px
        opacity: 0.5
        height: 60%
        top: 25%
        left: 0

@keyframes inputHighlighter
    from
        background: #64C8EB

    to
        width: 0
        background: transparent

// Input select
.select
    .select__control
        border-radius: 0 !important
        margin-top: 0 !important
        font-family: 'UniNeue'

        box-shadow: none !important
        outline: none !important
        border: none !important

        border-bottom: 2px solid rgba(0,0,0, 0.15) !important
        padding-top: 6px !important

        .select__indicator-separator
            background-color: transparent !important

        &--is-disabled
            background-color: #FFF !important
            border-radius: 0 !important

        svg
            fill: #64C8EB !important

        .select__dropdown-indicator
            +flex(row, flex-start, center)
            height: 36px !important
            width: 30px !important
            padding: 0 !important

        .select__value-container
            transition: all 0.2s ease
            justify-content: center
            align-items: flex-start
            height: 42px !important
            flex-direction: column
            padding: 0 !important
            display: flex

            & > .label
                color: rgba(0, 0, 0, 0.6) !important
                transition: all 0.2s ease
                margin-left: 0 !important
                padding-left: 5px
                font-weight: 500
                font-size: 16px
                // width: 100%
            
            & > div:nth-child(3)
                padding: 0 !important
                position: absolute
                height: 42px
                width: 100%
                margin: 0

                .select__input
                    padding: 18px 10px 2px 10px !important
                    
                    input
                        color: #000000 !important
                        font-weight: 500 !important
                        font-size: 16px !important

                        height: 100%
                        width: 100%
                    & > div:nth-child(2)
                        display: none !important
            &--has-value
                box-shadow: none !important

                & > .label
                    display: none

        &--is-focused
            border: none
            border-bottom: 1px solid #64C8EB

            justify-content: flex-start
            border-radius: 0 !important
            

            .select__value-container
                padding: 0 !important

                &--has-value
                    .label:first-child
                        display: block !important

                & > .label
                    color: #64C8EB !important
                    padding-left: 5px
                    position: absolute
                    margin-bottom: 4px
                    margin-top: -14px
                    font-weight: 500
                    font-size: 12px

                .select__input
                    padding: 18px 10px 2px 10px !important

                    input:focus
                        padding-left: 0px !important
                        color: #000000 !important
                        font-weight: 500 !important
                        font-size: 16px !important

        .select__placeholder
            margin-left: 0 !important
            font-weight: 500
            font-size: 16px
            color: rgba(0, 0, 0, 0.6)

            display: none !important

        .select__single-value
            font-weight: 500 !important
            font-size: 16px !important
            margin: 0 !important
            padding-left: 5px
            line-height: 1.15
            color: #000

            .label
                margin-bottom: 4px
                font-weight: 500
                margin-top: 3px
                font-size: 12px
                color: #64C8EB

    .select__menu
        border-radius: 0 !important
        margin-top: 0 !important
        border: none !important
        z-index: 100 !important

        .select__option
            transition: all 0.2s ease
            color: rgba(0, 0, 0, 0.8)
            font-weight: 500
            font-size: 16px
            cursor: pointer

            &:hover
                background-color: rgba(0, 0, 0, 0.1) !important
                color: #64C8EB !important

        .select__option--is-selected
            background-color: rgba(0, 0, 0, 0.1) !important
            color: #64C8EB !important

// Rounded Select
.rounded-select
    .rounded-select__control
        border-color: rgba(0, 0, 0, 0.15) !important
        border-style: solid !important
        border-width: 1px !important
        min-height: 46px !important
        margin-top: 0 !important
        border-radius: 6px

        .rounded-select__indicator-separator
            background-color: transparent !important

        .rounded-select__dropdown-indicator
            +flex(row, center, center)
            height: 46px !important
            width: 46px !important
            padding: 0 !important

        svg
            fill: #64C8EB !important
            height: 26px !important
            width: 26px !important

        .rounded-select__value-container
            transition: all 0.2s ease
            justify-content: center
            align-items: flex-start
            height: 48px !important
            flex-direction: column
            padding: 0 !important
            display: flex

            & > .label
                color: rgba(0, 0, 0, 0.46) !important
                transition: all 0.2s ease
                margin-left: 0 !important
                padding-left: 10px
                font-weight: 500
                font-size: 16px
                // width: 100%
            
            & > div:nth-child(3)
                padding: 0 !important
                position: absolute
                height: 48px
                width: 100%
                margin: 0

                .rounded-select__input
                    padding: 18px 10px 2px 10px !important
                    
                    input
                        color: #000000 !important
                        font-weight: 500 !important
                        font-size: 16px !important

                        height: 100%
                        width: 100%
                    & > div:nth-child(2)
                        display: none !important
            &--has-value
                box-shadow: none !important

                & > .label
                    display: none

        &--is-focused
            box-shadow: 0 0 1px transparentize(#5FC8ED, 0.9) !important
            border-color: rgba(0, 0, 0, 0.15) !important
            border-radius: 6px 6px 0 0 !important
            border-style: solid !important
            border-radius: 6px !important
            border-width: 1px !important
            justify-content: flex-start
            

            .rounded-select__value-container
                padding: 0 !important

                &--has-value
                    .label:first-child
                        display: block !important

                & > .label
                    padding-left: 10px
                    position: absolute
                    margin-bottom: 4px
                    margin-top: -14px
                    font-weight: 500
                    font-size: 12px

                .rounded-select__input
                    padding: 18px 10px 2px 10px !important

                    input:focus
                        padding-left: 0px !important
                        color: #000000 !important
                        font-weight: 500 !important
                        font-size: 16px !important
                

        .rounded-select__placeholder
            color: rgba(0, 0, 0, 0.6) !important
            margin-left: 0 !important
            font-weight: 500
            font-size: 16px

            display: none !important

        .rounded-select__single-value
            padding-left: 10px !important
            font-weight: 500 !important
            font-size: 16px !important
            height: 44px !important
            margin: 0 !important
            line-height: 1.15
            color: #000

            .label
                color: rgba(0,0,0, 0.6)
                margin-bottom: 4px
                font-weight: 500
                margin-top: 3px
                font-size: 12px

    .rounded-select__menu
        border-radius: 0 0 6px 6px !important
        z-index: 100 !important
        margin-top: 0 !important
        border: none !important

        .rounded-select__option
            transition: all 0.2s ease
            color: rgba(0, 0, 0, 0.8)
            font-weight: 500
            font-size: 16px
            cursor: pointer
            min-height: 32px

            .label
                display: none !important

            &:hover
                background-color: rgba(0, 0, 0, 0.1) !important
                color: #64C8EB !important

        .rounded-select__option--is-selected
            background-color: rgba(0, 0, 0, 0.1) !important
            color: #64C8EB !important
