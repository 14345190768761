.loading-indicator
    margin-top: 16px
    flex-direction: row
    align-items: center
    display: flex
    &--center
        justify-content: flex-start
    &--left
        justify-content: center
    &--right
        justify-content: flex-end
