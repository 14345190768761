.alert.panel
    background-color: #ffffff
    align-items: center
    border-radius: 4px
    padding: 16px 14px
    // max-width: 560px
    display: flex
    
    .icon
        border: 4px solid rgba(191, 197, 218, 0.36)
        +flex(row, center, center)
        background-color: #ffffff
        border-radius: 50%
        height: 36px
        width: 36px
    
    .title
        padding-left: 16px
        font-weight: 500
        font-size: 16px
        color: #6C6F88
        flex: 1
    
    .action
        margin: 0