.car.preview
    padding: 24px 60px

.car.preview, .profile.preview
    flex-direction: column
    display: flex

.drivers.page .profile.preview
    height: auto

.car.preview.base .content
    margin: 24px 0

    & > .title
        +flex(row, space-between, center)

        & > .text
            font-weight: 300
            font-size: 32px
            color: #000000

        .edit
            border: 1px solid rgba(0, 0, 0, 0.05)
            transition: all 0.35s ease
            align-items: center
            border-radius: 40px
            min-width: 44px
            cursor: pointer
            display: flex
            height: 44px

            &:hover
                border: 1px solid rgba(0, 0, 0, 0.15)

                .text
                    margin-right: 10px
                    margin-left: 10px
                    width: 101px

            .text
                transition: all 0.35s ease
                color: rgba(0, 0, 0, 0.76)
                margin-right: 5px
                margin-left: 5px
                overflow: hidden
                font-size: 15px
                width: 0px

            svg
                margin-left: 12px
                min-width: 22px
                height: 22px
                width: 22px

    & > .row
        margin-top: 10px !important

        & > .column
            flex: initial !important

    .photos
        margin-top: 40px
        display: flex

        .arrow
            align-items: center
            margin: 0 40px 40px
            display: flex

        .photo
            position: relative

            img, svg
                border-radius: 50%
                max-width: 100%
                height: 100%
                width: 100%

            &.car
                height: 128px
                width: 128px

            &.driver
                height: 128px
                width: 128px

            .checked
                +flex(row, center, center)

                background-color: #64C8EB
                border: 4px solid #FFF
                position: absolute
                border-radius: 50%
                height: 26px
                width: 26px
                bottom: -4px
                right: -8px

        .transport-card-info
            .title
                text-align: center
                margin-top: 10px
                font-weight: 500
                font-size: 16px
                color: #1C1C1C

            .subtitle
                text-align: center
                color: #6C6F88
                font-size: 14px

    .others
        flex-direction: column
        margin-left: 80px
        display: flex
        width: 400px

        .item
            flex-direction: column
            margin: 22px 0
            display: flex

            .title
                font-weight: 500
                font-size: 10px
                color: #6C6F88

            .text
                margin-top: 4px
                font-weight: 500
                font-size: 16px
                color: #1C1C1C

// .car.preview.base .content,
// .profile.docs.preview .content,
// .profile.base.preview .content
//     margin-top: 24px

    & > .title
        font-weight: 300
        font-size: 32px
        color: #000000

    .action
        max-width: 170px

    .divider
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2) 33%, rgba(255, 255, 255, 0) 0%)
        background-repeat: repeat-x
        background-position: bottom
        background-size: 12px 1px
        margin-top: 25px
        height: 1px

    .row
        margin-top: 40px
        display: flex

        .column
            flex: 1

            & > .title
                text-transform: uppercase
                font-weight: 700
                font-size: 14px
                color: #2E3043

            .items

                .item
                    flex-direction: column
                    margin: 22px 0
                    display: flex

                    .title
                        font-weight: 500
                        font-size: 10px
                        color: #6C6F88

                    .text
                        margin-top: 4px
                        font-weight: 500
                        font-size: 16px
                        color: #1C1C1C

                    .image
                        margin-top: 8px

                        img
                            border-radius: 5px
                            height: 70px
                            width: auto
