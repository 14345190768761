.modal.profile-sended
    +flex(row, center, center)
    
    .content
        +flex(column, center, center)
        background-color: #fff
        border-radius: 6px
        z-index: 100
        height: 300px
        width: 500px

        .message
            margin: 20px 30px 40px 30px
            color: rgba(0,0,0, 0.7)
            text-align: center
            font-weight: 500
            font-size: 18px
