.drivers.page .page-content .car-creation
	.base.form
		padding: 40px 60px 40px

		.form
			width: 400px

	.docs.form
		padding: 40px 60px 40px
		background-color: #F1F2F5

	.dimensions.form
		padding: 40px 60px 0

		.form
			width: 400px

	.base.form, .docs.form, .dimensions.form
		display: flex

		.photos
			margin-right: 35px
			min-width: 93px
		
		.common
			flex-direction: column
			display: flex
			flex: 1

			.header
				margin-bottom: 20px

				.title
					font-weight: 300
					font-size: 32px
					color: #000000

				.subtitle
					min-width: 450px
					margin-top: 15px
					font-weight: 400
					font-size: 20px
					color: #989aac

			.builder.fields
				max-width: 400px

				.form-item
					&.input, &.select
						margin: 10px 0

					&.input
						min-height: 46px !important

				.header
					text-transform: uppercase
					letter-spacing: 1.1px
					margin-bottom: 20px
					font-weight: bold
					margin-top: 20px
					font-size: 14px
					color: #2e3043

.drivers.page .page-content .car-creation
	.actions
		+flex(row, flex-start, flex-start)
		margin: 25px 0 50px 200px

	.action
		padding: 0 16px
		width: auto

	.cancel-button
		margin-left: 20px

	.loading
		background-color: #E0E0E0
		color: rgba(0,0,0,75)

.drivers.page .page-content .car-creation .base.form	
	.photos
		+flex(column, center, center)

		.arrow
			margin: 20px 0

		.driver
			+flex(column, center, center)

			.avatar
				border: 1px solid rgba(0, 0, 0, 0.1)

				+flex(row, center, center)

				border-radius: 50%
				overflow: hidden
				height: 82px
				width: 82px

				img
					height: 82px
					width: 82px

				&.empty img
					height: 50px
					width: auto

			.fullname
				color: rgba(0, 0, 0, 0.9)
				text-align: center
				font-weight: 500
				margin-top: 10px
				font-size: 16px

.drivers.page .page-content .car-creation .docs.form
	overflow: hidden
	height: auto

	.photos
		+flex(row, center, flex-start)

		padding-top: 50px
	
	.certificates.forms.list, .insurances.forms.list
		flex-direction: column
		overflow: visible
		max-width: 400px
		display: flex
		height: auto
		flex: none
		margin-top: 0

		.certificate.item
			margin: 30px 0 20px

		.insurance.item
			margin: 0

		.certificate.item, .insurance.item
			background-color: transparent
			flex-direction: column
			display: flex
			position: relative

			.icons
				position: absolute
				left: -135px

				.icon
					border: 1px solid rgba(0, 0, 0, 0.1)

					+flex(row, center, center)

					border-radius: 50%
					overflow: hidden
					height: 82px
					width: 82px

					img
						height: 82px
						width: auto

			.form
				.action
					max-width: 180px
					&.loading
						background-color: lighten(#2E3043, 10%)
						color: rgba(255,255,255, 0.7)
						
						& > div
							margin-right: 10px

				.form-item.date.rounded
					margin: 10px 0

			.titlebar
				+flex(row, flex-start, center)
				
				min-height: 48px
				height: 48px
				
				.titles
					+flex(column, center, flex-start)
					
					.header
						color: rgba(0, 0, 0, 0.78)
						font-weight: 300
						font-size: 22px
						margin: 0